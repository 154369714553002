import React, { useEffect, useRef, useState } from "react";
import { FaPen } from "react-icons/fa6";
import "../Home/homePage.css";
import Sidebar from "../Sidebar/sidebar";
import TopNavbar from "../Navabr/navbar";
import { notification, Upload, Flex, Spin } from "antd";
import {
  InboxOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import {fetchIpAddress} from '../../../controllers/API';
import {updateProfile} from '../../../controllers/investor/accounts'
import { NOIMAGE } from "../../../config/constants";

const Profile = () => {
  const targetRef = useRef();
  const USERDATA = JSON.parse(sessionStorage.getItem('USER-INFO'));
  const [editMode, setEditMode] = useState(false);
  const [EDITDATA, setEditData] = useState(USERDATA || '');
  const [investorImage, setInvestorImage]=useState(USERDATA?.investor_image);
  const [tempInvestorImage, setTempInvestorImage]=useState('');
  const [isLoading, setIsLoading] = useState(false);
  const onChange = (e)=>{
    try {
      const {name, value} = e.target;
      setEditData((pre)=>({
        ...pre,
        [name] : value
      }))
    } catch (error) {
      
    }
  }

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const { investor_first_name, investor_last_name, investor_designation, investor_firm_name, investor_description } = EDITDATA;
      const ipAddress = await fetchIpAddress();
      let form = new FormData();
      form.append('investor_first_name',investor_first_name);
      form.append('investor_last_name',investor_last_name);
      form.append('investor_designation',investor_designation);
      form.append('investor_firm_name',investor_firm_name);
      form.append('investor_description',investor_description);
      form.append('ipAddress',ipAddress);
      if(investorImage){
        form.append('file',investorImage)
      }
      const res = await updateProfile(form);
      if(res.status === true){
        setEditMode(false);
        notification.open({
          message: "Success",
          description: `Profile updated successfully.`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        });
      } else{
        notification.open({
          message: "Oops!",
          description: `${res?.message}`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }
      setIsLoading(false);
      setTempInvestorImage(null);
    } catch (error) {
      setIsLoading(false);
      setTempInvestorImage(null);
    }
  }

  useEffect(()=>{
    if(USERDATA?.first_time_login === true){
      setEditMode(true);
    }
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
    document.title = "Chiratae Ventures | Spotlight | Profile";
  },[]);

  const handleImageChnage = (e) => {
    const len = e.fileList?.length;
    const index = len - 1;
    setInvestorImage(e.fileList[index]?.originFileObj);
    const tempImg = URL.createObjectURL(e.fileList[index]?.originFileObj);
    setTempInvestorImage(tempImg);
  }

  return (
    <>
      <TopNavbar></TopNavbar>
      <div className="main-wrapper" ref={targetRef}> 
        <div>
          <Sidebar></Sidebar>
        </div>
        <div className="main_contant">
          <div className="container-fluid py-5 all_page_for_top_heading">
            <h2 className=" text-uppercase all_heading">Profile</h2>
            <div className="">
              <h4 className="profile_info_heading">Profile Info</h4>
              <div className="d-flex align-items-center home_beepkart_fa_pen">
                {(tempInvestorImage && editMode) ? (
                  <img
                    src={tempInvestorImage}
                    alt="Logo"
                    className="profile-logo"
                    width='100px'
                  />
                ):(
                  <img
                    src={USERDATA?.investor_image || NOIMAGE}
                    alt="Logo"
                    className="profile-logo"
                    width='100px'
                  />
                )}
                {editMode && (
                  <Upload
                  showUploadList={false}
                  progress={false}
                  onChange={handleImageChnage}
                  multiple={false}
                  >
                    <div className="position-relative">
                      <button className="btn btn-sm rounded-circle edit-btn home_fa_pen_button">
                        <FaPen />
                      </button>
                    </div>
                  </Upload>
                )}
              </div>
              <form>
                <div className="row">
                  <div className="col-md-4 mb-2">
                    <input
                      type="text"
                      name="investor_first_name"
                      id="investor_first_name"
                      placeholder="First Name"
                      className="form-control all_input"
                      onChange={onChange}
                      disabled={!editMode}
                      value={EDITDATA?.investor_first_name}
                    />
                  </div>
                  <div className="col-md-4 mb-2">
                    <input
                      type="text"
                      name="investor_last_name"
                      id="investor_last_name"
                      placeholder="Last Name"
                      className="form-control all_input"
                      onChange={onChange}
                      disabled={!editMode}
                      value={EDITDATA?.investor_last_name}
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <input
                      type="Number"
                      name="investor_phone"
                      id="investor_phone"
                      placeholder="Mobile Number"
                      className="form-control all_input"
                      disabled={!editMode}
                      value={EDITDATA?.investor_phone}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8 mb-3">
                    <input
                      type="email"
                      name="investor_email"
                      id="investor_email"
                      placeholder="Email Address"
                      className="form-control all_input"
                      disabled={!editMode}
                      value={EDITDATA?.investor_email}
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <input
                      type="text"
                      name="investor_designation"
                      id="investor_designation"
                      placeholder="Designation"
                      onChange={onChange}
                      className="form-control all_input"
                      disabled={!editMode}
                      value={EDITDATA?.investor_designation}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <input
                      type="text"
                      name="investor_firm_name"
                      id="investor_firm_name"
                      placeholder="Investor Company’s Name"
                      onChange={onChange}
                      className="form-control all_input"
                      disabled={!editMode}
                      value={EDITDATA?.investor_firm_name}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <textarea
                      name="investor_description"
                      id="investor_description"
                      className="form-control all_input_text_area"
                      placeholder="Description"
                      rows="4"
                      onChange={onChange}
                      disabled={!editMode}
                      value={EDITDATA?.investor_description}
                    ></textarea>
                  </div>
                </div>
                {editMode === true ?(
                  <div className="profile_submit_btn">
                    <button type="button" onClick={onSubmit}>Save & Continue</button>
                  </div>
                ):(
                  <div className="profile_submit_btn">
                    <button type="button" onClick={()=>setEditMode(true)}>Edit Details</button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <Flex align="center" gap="middle">
        <Spin 
            size="large" 
            spinning={isLoading}
            fullscreen={true}
        />
      </Flex>
    </>
  );
}

export default Profile;
