import  { API_BASE_URL }  from '../../config/constants';
import {getRequest, postRequest} from '../API';

/*********************************************************
* Function Name : meetingList
* Description   : This function is used for get meetings list
* By            : Afsar Ali
* Date          : 23-08-2024 
*********************************************************/
export const meetingList = async (options) =>{
    try {
        const params = {
            url : `${API_BASE_URL}/meetings/list`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result, count : res?.data?.response?.count };
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : changeMeetingStatus
* Description   : This function is used for chnage status of meeting
* By            : Afsar Ali
* Date          : 23-08-2024 
*********************************************************/
export const changeMeetingStatus = async (options) =>{
    try {
        const params = {
            url : `${API_BASE_URL}/meetings/chnage-status`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : reasonList
* Description   : This function is used for get reason list
* By            : Afsar Ali
* Date          : 24-08-2024 
*********************************************************/
export const reasonList = async (options) =>{
    try {
        const params = {
            url : `${API_BASE_URL}/common/reason-list`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result, count : res?.data?.response?.count };
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : rescheduleMeeting
* Description   : This function is used for reschedule meeting
* By            : Afsar Ali
* Date          : 24-08-2024 
*********************************************************/
export const rescheduleMeeting = async (options) =>{
    try {
        const params = {
            url : `${API_BASE_URL}/meetings/reschedule`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : cancelMeeting
* Description   : This function is used for cancel meeting
* By            : Afsar Ali
* Date          : 24-08-2024 
*********************************************************/
export const cancelMeeting = async (options) =>{
    try {
        const params = {
            url : `${API_BASE_URL}/meetings/cancel`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : writefeedback
* Description   : This function is used for write feedback of meeting
* By            : Afsar Ali
* Date          : 26-08-2024 
*********************************************************/
export const writefeedback = async (options) =>{
    try {
        const params = {
            url : `${API_BASE_URL}/meetings/write-feedback`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function
