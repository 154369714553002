import { useState, useEffect, useRef } from "react";
import { Accordion, Card } from "react-bootstrap";
import TopNavbar from "../Navabr/navbar";
import Sidebar from "../Sidebar/sidebar";
import { BsThreeDotsVertical } from "react-icons/bs";
// import "../../Investor/Compinies/companies.css";
import "../Compinies/companies.css";
import moment from "moment";

import NoMetting from "./NoMetting";
import {Skeleton, notification} from 'antd';
import {
  InboxOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { meetingList, writefeedback } from "../../../controllers/meetings/meetingController";
import { Link } from "react-router-dom";
import CancelRequest from "./CancelRequest";
import RescheduleRequest from "./RescheduleRequest";
import { createImageFromInitials } from "../../../controllers/common";
const FeedbackList = () => {
  const targetRef = useRef();
  const [activeAccordion, setActiveAccordion] = useState(0);

  const handleAccordionClick = (eventKey) => {
    if(eventKey !== null){
      setActiveAccordion(activeAccordion === eventKey ? null : eventKey);
    }
  };

  const [feedbackPopup, setFeedbackPopup] = useState(false);
  
  const handleFeedbackModal = () => {
    setFeedbackPopup(!feedbackPopup);
    setFeedback('');
  }

  const [dropdown, setDropdown] = useState(false);
  const dropdownRef = useRef(null);

  
  const [page, setPage] =useState('List');
  const [isLoading, setIsLoading] =useState(true);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [TOTALCOUNT, setTotalCount] = useState(0);
  const [LIMIT, setLimit] = useState(5);
  const [SKIP, setSkip] = useState(0);

  const [feedback, setFeedback] = useState('');
  const handleFeedbackChage = (e) =>{
    setFeedback(e.target.value);
  }

  const [accordionBody, setAccordionBody] = useState({});

  const handleAccordionBody = (index)=>{
    setAccordionBody((pre)=>({
      ...pre,
      [index] : !accordionBody[index]
    }))
  }

  const [meetingId, setMeetingId] = useState('');


  const dropdown_handle = (index) => {
    setDropdown((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdown(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [page]);

  const getMeeting = async () => {
    try {
      setIsLoading(true);
      const options = {
        type: "",
        condition: { 
          isoMeetingDate : {'$lte' : new Date(moment().add(6, 'hours').add(30, 'minutes').toISOString())},
          isCancelled : {'$ne' : 'Y'},
          isReschedule : {'$ne' : 'Y'} 
        },
        select: {},
        sort: { _id: -1 },
        // skip: SKIP ? SKIP : 0,
        // limit: LIMIT ? LIMIT : 5,
      }
      const res = await meetingList(options);
      if(res.status === true){
        setListData(res.result);
        setTotalCount(res.count);
        if(res?.result?.length === 0){
          setPage('Empty');
        }
      }
      setIsLoading(false)
    } catch (error) {
      setListData('');
      setListData(0)
      setIsLoading(false);
    }
  }

  const [expanded, setExpanded] = useState({});

  const toggleExpand = (index) => {
    setExpanded((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  useEffect(()=>{
    getMeeting();
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
    document.title = "Chiratae Ventures | Spotlight | Feedback list";
  },[page, feedbackPopup]);


  const loadMoreData = async () => {
    try {
      const skip = ALLLISTDATA.length;
      const options = {
        type: "",
        condition: { 
          isoMeetingDate : {'$lte' : new Date(moment().add(6, 'hours').add(30, 'minutes').toISOString())},
          isCancelled : {'$ne' : 'Y'},
          isReschedule : {'$ne' : 'Y'} 
        },
        select: {},
        sort: { _id: -1 },
        skip: skip ? skip : 0,
        limit: 5,
      }
      const res = await meetingList(options);
      if(res?.status === true || res?.result?.length > 0){
        setListData((prev) => [...prev, ...res.result]);
      }
    } catch (error) {
      
    }
  }
  const lastItemRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if(TOTALCOUNT !== ALLLISTDATA.length){
            // loadMoreData();
          }
          // Optionally disconnect the observer if you only need the alert once
          observer.disconnect();
        }
      },
      { threshold: 1.0 } // Adjust threshold as needed
    );

    if (lastItemRef.current) {
      observer.observe(lastItemRef.current);
    }

    return () => {
      if (lastItemRef.current) {
        observer.unobserve(lastItemRef.current);
      }
    };
  }, [ALLLISTDATA]);


  const dataTimeFormat = (date) => {
    if (date && typeof date === 'string') {
      const dateParts = date.split('-');
      if (dateParts.length === 3) {
          const formattedDate = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
          const options = { day: '2-digit', month: 'long', year: 'numeric' };
          const convertedDate = formattedDate.toLocaleDateString('en-US', options);
          return convertedDate
      } else {
          console.error('Invalid date format');
      }
    } else {
        console.error('Date string is undefined or not a string');
    }
  }

  const timeDifferance = (time_from , time_to ) => {
    const [fromHours, fromMinutes] = time_from.split(':').map(Number);
    const [toHours, toMinutes] = time_to.split(':').map(Number);

    const fromDate = new Date(2024, 7, 24, fromHours, fromMinutes); 
    const toDate = new Date(2024, 7, 24, toHours, toMinutes);

    const diffInMilliseconds = toDate - fromDate;

    const diffInMinutes = diffInMilliseconds / (1000 * 60);

    const diffHours = Math.floor(diffInMinutes / 60);
    const diffMinutes = diffInMinutes % 60;

    return `${diffHours} hours and ${diffMinutes} minutes`
  }

  const toggleFeedback = (index) => {
    setMeetingId(ALLLISTDATA[index]?._id);
    handleFeedbackModal();
  }

  const submitFeedback = async () => {
    try {
      if(!meetingId){
        notification.open({
          message: "Opps!",
          description: `Meeting not found.`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
        handleFeedbackModal();
        setMeetingId('');
      } else if(!feedback){
        notification.open({
          message: "Opps!",
          description: `Feedback required.`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      } else{
        const options = {
          meet_id : meetingId,
          investorFeedback : feedback
        }
        const res = await writefeedback(options);
        if(res.status === true){
          notification.open({
            message: "Success",
            description: `Feedback sent successfully.`,
            placement: "topRight",
            icon: <CheckCircleOutlined style={{ color: "green" }} />,
            duration: 2,
          });
          handleFeedbackModal();
          setMeetingId('');
        }else{
          notification.open({
            message: "Success",
            description: `${res?.message}`,
            placement: "topRight",
            icon: <CheckCircleOutlined style={{ color: "green" }} />,
            duration: 2,
          });
          handleFeedbackModal();
          setMeetingId('');
        }
      }
    } catch (error) {
      notification.open({
        message: "Success",
        description: `Please try again.`,
        placement: "topRight",
        icon: <CheckCircleOutlined style={{ color: "green" }} />,
        duration: 2,
      });
      handleFeedbackModal();
      setMeetingId('');
    }
  }


  return (
    <>
      <TopNavbar />
      <div className="main-wrapper" ref={targetRef}>
        <div>
          <Sidebar />
        </div>
        {page === 'List' && (
          <div className="main_contant">
            <div className="container-fluid all_page_for_top_heading">
              <h2 className=" text-uppercase all_heading">Feedbacks</h2>
            </div>
            {ALLLISTDATA?.length > 0 ? (
              ALLLISTDATA.map((items, index) => (
              <div key={index} className="container-fluid companies_section_main_div mb-3" ref={index === ALLLISTDATA.length - 1 ? lastItemRef : null}>
                <div className="d-flex align-items-center justify-content-between companies_select_all_div">
                  <div className="d-flex align-items-center companies_beep_cart_img">
                    {items?.company_image?(
                      <img
                        src={items?.company_image}
                        alt="Company Logo"
                        className="rounded"
                        style={{ marginRight: "10px" }}
                      />
                    ):(
                      <img
                        src={createImageFromInitials(100, `${items?.company_name.length > 8?items.company_name.substring(0,8)+'..':items?.company_name}`, '#ededed')}
                        alt="Company Logo"
                        className="rounded"
                        style={{ marginRight: "10px"}}
                      />
                    )}
                    <div>
                      <h6 className="mb-0" style={{ fontSize: "16px" }}>
                      {items?.company_id?.company_name}
                      </h6>
                      <small className="text-muted">{`${items?.company_id?.company_sector}`}</small>
                    </div>
                  </div>
                  <div className="d-flex align-items-center companies_beep_cart_img companies_svg_btn">
                    {items.isInvestorFeedback === 'Y' ? (
                      <div className="started_in_mint_ago">
                        <button type="button">Feedback Sent</button>
                      </div>
                    ):(
                      <div className="profile_submit_btn">
                        <button type="button" onClick={()=>toggleFeedback(index)}>Write a feedback</button>
                      </div>
                    )}
                    
                  </div>
                </div>
                <Accordion
                  activeKey={activeAccordion}
                  onSelect={handleAccordionClick}
                  className="companies_accrodian"
                >
                  <Card
                    style={{ border: "1px solid #e0e0e0", borderRadius: "8px" }}
                  >
                    <Accordion.Item eventKey={index} className="company_accodian_item">
                      <Accordion.Header className="metting_deatils_accordian">
                        <span className={ !accordionBody[index]?"active-text" : "" } onClick={()=>handleAccordionBody(index)} >
                          About the company
                        </span>
                        <span className={ accordionBody[index]?"active-text" : "" } onClick={()=>handleAccordionBody(index)}>Meeting details</span>
                      </Accordion.Header>
                      {accordionBody[index] ? (
                        <Accordion.Body style={{ padding: "1rem" }} className="companies_border_top" >
                          <p>
                            Date & Time : <span>{dataTimeFormat(items.date)} {moment(`2024-01-01 ${items.time_from}`).format('hh:mm:A')}</span>
                          </p>
                          <p>
                            Duration : <span>{timeDifferance(items.time_from, items.time_to)}</span>
                          </p>
                          {/* <p>
                            Priority : <span>{(items?.priority === 1 || items?.priority === '1')?'First' : 'Second'}</span>
                          </p>
                          <p>
                            Table No. : {items?.table}
                          </p> */}
                        </Accordion.Body>
                      ):(
                        <Accordion.Body style={{ padding: "1rem" }} className="companies_border_top" >
                          <p>
                            Company Name : <span>{items?.company_id?.company_name}</span>
                          </p>
                          <p>
                            Founder Name : <span>{`${items?.company_id?.company_founder_name}`}</span>
                          </p>
                          <p>
                            CEO Name : <span>{`${items?.company_id?.company_ceo_name}`}</span>
                          </p>
                          <p>
                            Stage : <span>{`${items?.company_id?.company_stage}`}</span>
                          </p>
                          <p>
                            Sector : <span>{`${items?.company_id?.company_sector}`}</span>
                          </p>
                          <p>
                            Description :{" "}
                            {expanded[index]?
                            <span>
                              {`${items?.company_id?.company_description} `}
                            </span> : <span>
                            {`${items?.company_id?.company_description.slice(0, 175)} `}
                            </span>}
                            {items?.company_id?.company_description?.length > 175 && (
                              <Link type="button" onClick={() => toggleExpand(index)}>
                                {expanded[index] ? 'Read less' : 'Read more'}
                              </Link>
                            )}
                          </p>
                        </Accordion.Body>
                      )}
                      
                    </Accordion.Item>
                  </Card>
                </Accordion>
              </div>
            ))):(<>
              <div className="container-fluid companies_section_main_div mb-3">
                <div className="d-flex align-items-center justify-content-between companies_select_all_div">
                  <Skeleton avatar/>
                </div>
              </div>
              <div className="container-fluid companies_section_main_div mb-3">
                <div className="d-flex align-items-center justify-content-between companies_select_all_div">
                  <Skeleton avatar/>
                </div>
              </div>
              <div className="container-fluid companies_section_main_div mb-3">
                <div className="d-flex align-items-center justify-content-between companies_select_all_div">
                  <Skeleton avatar/>
                </div>
              </div>
              <div className="container-fluid companies_section_main_div mb-3">
                <div className="d-flex align-items-center justify-content-between companies_select_all_div">
                  <Skeleton avatar/>
                </div>
              </div>
            </>)}
          </div>
        )}

        {page === 'Empty' && (
          <NoMetting />
        )}

        <div
          className="modal fade show"
          style={{ display: `${feedbackPopup?'block':'none'}`, backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div
            className="modal-dialog modal-dialog-centered"
            style={{ maxWidth: "400px" }}
          >
            <div className="modal-content">
              <div className="modal-body text-center p-4 login_popup_modal">
                <h5 className="mb-4  scan_the_qr_code">
                  Write your feedback
                </h5>
                 {/* <div className="qr_code_img">
                    <img src="/charaiteImage/qr_code.png" alt="" />
                 </div> */}
                 <div className="row">
                  <textarea 
                    name="feedback"
                    id="feedback"
                    value={feedback}
                    onChange={handleFeedbackChage}
                    placeholder="Please write your feedback..."
                    className="form-control all_input"
                    rows={6}
                    >
                  </textarea>
                  <br/>
                  {"  "}
                  <br/>
                  <div className="profile_submit_btn">
                      <button type="button" onClick={submitFeedback} >Submit</button>
                  </div>
                </div>
              </div>
              <div className="position-absolute user_login_top_right_cross_btn">
                <button className="top-0 end-0 m-3" onClick={handleFeedbackModal}>
                  x
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default FeedbackList;
