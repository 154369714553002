import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Investor/ScheduleMetting/scheduleMetting.css";

function PortfolioscheduleSlider() {
  const sliderRef = useRef(null);
  const [activeCard, setActiveCard] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (sliderRef.current) {
        sliderRef.current.slickGoTo(0);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCardClick = (index) => {
    setActiveCard(index);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    beforeChange: (current, next) => setActiveCard(next), // Update the active card
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div className="container mt-2 home_bottom_carousls">
        <Slider ref={sliderRef} {...settings}>
          {[...Array(3).keys()].map((index) => (
            <div key={index} className="px-2 ">
              <div
                className={`card custom-card mx-auto schedule_metting_slider_card ${
                  activeCard === index ? "active" : ""
                }`}
                onClick={() => handleCardClick(index)} // Update active card on click
              >
                <div className="row g-0 schedule_metting_row">
                  <div className="col-md-3 d-flex align-items-center justify-content-center">
                    <img
                      src={`/charaiteImage/schedule${index + 1}.png`}
                      alt="Aura Logo"
                      className="img-fluid logo"
                    />
                  </div>
                  <div className="col-md-9">
                    <div className="card-body schedule_metting_for_content">
                      <p className="card-text date-time">
                        3 Dec, 2024, at 02:00PM
                      </p>
                      <h5 className="card-title company-name">
                        Portfolio Company Name...
                      </h5>
                      <p className="card-text founder">
                        Mr. Brian Roberto (Founder)
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-3 schedule_metting_button">
                    <button type="button" className="priority">
                      Priority 1
                    </button>
                    <button type="button" className="table-number">
                      Table No: 45
                    </button>
                  </div>
                </div>
                <div className="card-footer text-center schedule_metting_in_min">
                  {activeCard === index
                    ? "Started 30 mins. ago"
                    : "In 30 mins."}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default PortfolioscheduleSlider;