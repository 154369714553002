import Portfoliosidebar from "../Sidebar/Portfoliosidebar";
import "../../Investor/Metting/metting.css";
import TopNavbar from "../../Investor/Navabr/navbar";
import mettingLottie from "../metiingLottie.json";
import Lottie from "react-lottie";

function NoDataFound() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: mettingLottie, // Correct key here
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return ( 
    <div className="no_metting_to_show">
      <div className="d-flex justify-content-center metting_animation_sec">
        <Lottie options={defaultOptions} />
        <button className="mt-3">No interest to show</button>
      </div>
    </div>
  );
}

export default NoDataFound;
