import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment";
import { Skeleton } from "antd";

const Meetingslider = ({meetingListData}) => {
  const sliderRef = useRef(null);
  const [activeCard, setActiveCard] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      if (sliderRef.current) {
        sliderRef.current.slickGoTo(0);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCardClick = (index) => {
    setActiveCard(index);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    beforeChange: (current, next) => setActiveCard(next), // Update the active card
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const dataTimeFormat = (date) => {
    if (date && typeof date === 'string') {
      const dateParts = date.split('-');
      if (dateParts.length === 3) {
          const formattedDate = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
          const options = { day: '2-digit', month: 'short', year: 'numeric' };
          const convertedDate = formattedDate.toLocaleDateString('en-US', options);
          return convertedDate
      } else {
          console.error('Invalid date format');
      }
    } else {
        console.error('Date string is undefined or not a string');
    }
  }

  return (
    <div>
      <div className="container mt-2 home_bottom_carousls">
        <Slider ref={sliderRef} {...settings}>
          {meetingListData.map((item, index) => (
            <div key={index} className="px-2 ">
              <div
                className={`card custom-card mx-auto schedule_metting_slider_card ${
                  activeCard === index ? "active" : ""
                }`}
                onClick={() => handleCardClick(index)} // Update active card on click
              >
                <div className="row g-0 schedule_metting_row">
                  <div className="col-md-3 d-flex align-items-center justify-content-center">
                    {item?.investor_id?.investor_image?(
                      <img
                        src={item?.investor_id?.investor_image}
                        alt="Aura Logo"
                        className="img-fluid logo"
                      />
                    ):(
                      <Skeleton avatar paragraph={false} title={false} />
                    )}
                  </div>
                  <div className="col-md-9">
                    <div className="card-body schedule_metting_for_content">
                      <p className="card-text date-time">
                        {dataTimeFormat(item.date)}, at {moment(`2024-01-01 ${item.time_from}`).format('hh:mm A')}
                      </p>
                      <h5 className="card-title company-name">
                        {item?.investor_id?.investor_firm_name.length > 22?`${item?.investor_id?.investor_firm_name.substring(0,22)}...`:item?.investor_id?.investor_firm_name}
                      </h5>
                      <p className="card-text founder">
                        {`${item?.investor_id?.investor_first_name} ${item?.investor_id?.investor_last_name} (${item?.investor_id?.investor_designation})`.substring(0,22)+`...`} 
                      </p>
                    </div>
                  </div>
                  {/* <div className="d-flex justify-content-between mb-3 schedule_metting_button">
                    <button type="button" className="priority">
                      Priority {item?.priority}
                    </button>
                    <button type="button" className="table-number">
                      Table No: {item?.table.substring(6)}
                    </button>
                  </div> */}
                </div>
                {/* <div className="card-footer text-center schedule_metting_in_min">
                  {activeCard === index
                    ? "Started 30 mins. ago"
                    : "In 30 mins."}
                </div>
                 */}
                 <div className="card-footer text-center schedule_metting_in_min">
                 {dataTimeFormat(item.date)}, at {moment(`2024-01-01 ${item.time_from}`).format('hh:mm A')}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Meetingslider;
