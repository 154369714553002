import React, { useState, useEffect, useRef } from "react";
import { Accordion, Card } from "react-bootstrap";
import Sidebar from "../Sidebar/sidebar";
import TopNavbar from "..//Navabr/navbar";
import "./interest.css";
import { FaTh, FaServer } from "react-icons/fa";

import { interestList } from '../../../controllers/interest/interestController';
import { Skeleton } from "antd";
import { Link } from "react-router-dom";

import NoDataFound from "./NoDataFound";
import { createImageFromInitials } from "../../../controllers/common";
const List = () => {
  const targetRef = useRef();
  const [activeAccordion, setActiveAccordion] = useState("0");

  const handleAccordionClick = (eventKey) => {
    setActiveAccordion(activeAccordion === eventKey ? null : eventKey);
  };

  const USERDATA = JSON.parse(sessionStorage.getItem('USER-INFO'));
  const [isLoading, setIsLoading] = useState(true)
  const [interest, setInterest] = useState('');
  const [expanded, setExpanded] = useState({});
  const [showStatus, setShowStatus] = useState('');
  const [searchText, setSearchText] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [dataView, setDataView]= useState('grid');
  const toggleExpand = (index) => {
    setExpanded((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleSearchChnage = (e) =>{
    const {value} = e.target;
    if(e.key === 'Enter'){
      handleSearch(e);
    }else{
      setSearchText(value);
    }
  }
  const handleSearch = (e) => {
    e.preventDefault();
    // if(searchText.length > 0){
      setIsSearch(!isSearch);
    // }
  }
  
  const getInterestList = async () =>{
    setIsLoading(true);
    setInterest([]);
    try {
      const options = {
        ...(searchText?{searchText : searchText}:null),
        condition : { 
          investor_data : USERDATA._id,
          ...(showStatus?{status : showStatus}:null)
        }
      }
      const res = await interestList(options);
      if(res.status === true){
        setInterest(res.result);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(()=>{
    getInterestList();
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
    document.title = "Chiratae Ventures | Spotlight | Interest list";
  },[showStatus, isSearch]);

  return (
    <React.Fragment>
      <TopNavbar></TopNavbar>
      <div className="main-wrapper" ref={targetRef}>
        <div>
          <Sidebar></Sidebar>
        </div>
        <div className="main_contant">
          <div className="container-fluid all_page_for_top_heading">
            <h2 className=" text-uppercase all_heading">Interests</h2>
          </div>

          <div className="container-fluid all_page_for_top_heading">
            <form onSubmit={handleSearch}>
              <div className="row">
                <div className="col-sm-9 col-md-9 col-lg-9">
                  <input className="form-control all_input" type="text" name="search" id="search" onChange={handleSearchChnage} value={searchText} placeholder="Search interest..."/>
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2">
                  <div className="profile_submit_btn">
                    <button type="submit" id="selected">Search</button>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1 col-lg-1">
                  {dataView === 'table' ? (
                    <div title="Grid view"  className="profile_submit_btn" onClick={()=>setDataView('grid')}>
                      <button type="button" id="selected"><FaTh/></button>
                    </div>
                  ) : (
                    <div title="Table view" className="profile_submit_btn" onClick={()=>setDataView('table')}>
                      <button type="button" id="selected"><FaServer/></button>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>

          <div className="companies_section_main_div mb-3">
            <div className="d-flex align-items-center justify-content-between companies_select_all_div">
            <div className="profile_submit_btn">
                <div className="started_in_mint_ago">
                  <button type="button" onClick={()=>setShowStatus('')}>All</button>
                  <button type="button" onClick={()=>setShowStatus('P')}>Pending</button>
                  <button type="button" onClick={()=>setShowStatus('A')}>Accepted</button>
                  <button type="button" onClick={()=>setShowStatus('R')}>Declined</button>
                </div>
            </div>
            </div>
          </div>
          {interest?.length > 0 ? (
            dataView === 'grid' ? (
              interest.map((items, index) => (
                <div className="container-fluid companies_section_main_div mb-3">
                  <div className="d-flex align-items-center justify-content-between companies_select_all_div">
                    <div className="d-flex align-items-center companies_beep_cart_img">
                      {items?.company_data?.company_image?(
                        <img
                          src={items?.company_data?.company_image}
                          alt="Company Logo"
                          className="rounded"
                          style={{ marginRight: "10px" }}
                        />
                      ):(
                        <img
                          src={createImageFromInitials(100, `${items?.company_data?.company_name?.length > 8?items.company_data.company_name.substring(0,8)+'..':items?.company_data.company_name}`, '#ededed')}
                          alt="Company Logo"
                          className="rounded"
                          style={{ marginRight: "10px" }}
                        />
                      )}
                      <div>
                        <h6 className="mb-0" style={{ fontSize: "16px" }}>
                        {`${items?.company_data?.company_name}`}
                        </h6>
                        <small className="text-muted">{`${items?.company_data?.company_sector} ${items?.company_data?.company_sector}`}</small>
                      </div>
                    </div>
                    <div className="profile_submit_btn">
                        <div className="started_in_mint_ago">
                          {items?.status==='P' && ( <button type="button">Pending</button> )}
                          {items?.status==='A' && ( <button type="button">Accepted</button> )}
                          {items?.status==='R' && ( <button type="button">Declined</button> )}
                        </div>
                    </div>
                  </div>
                  <Accordion
                    activeKey={activeAccordion}
                    onSelect={handleAccordionClick}
                    className="companies_accrodian"
                  >
                    <Card
                      style={{ border: "1px solid #e0e0e0", borderRadius: "8px" }}
                    >
                      <Accordion.Item eventKey={index} className="company_accodian_item">
                        <Accordion.Header>
                          <span>About the company</span>
                        </Accordion.Header>
                        <Accordion.Body
                          style={{ padding: "1rem" }}
                          className="companies_border_top"
                        >
                          <p>Company Name : <span>{items?.company_data?.company_name?items?.company_data?.company_name:''}</span></p>
                          {items?.company_data?.company_founder_name && (<p> Founder : <span>{`${items?.company_data?.company_founder_name}`}</span> </p>)}
                          {items?.company_data?.company_ceo_name && (<p> CEO : <span>{`${items?.company_data?.company_ceo_name}`}</span> </p>)}
                          <p>Company stage : <span>{items?.company_data?.company_stage?items.company_data?.company_stage:''}</span></p>
                          <p>Sector : <span>{items?.company_data?.company_sector?items.company_data?.company_sector:''}</span></p>
                          <p> Description :{" "}
                            {expanded[index]?
                              <span>
                                {`${items?.company_data?.company_description} `}
                              </span> : <span>
                              {`${items?.company_data?.company_description.slice(0, 175)} `}
                              </span>}
                              {items?.company_data?.company_description?.length > 175 && (
                                <Link type="button" onClick={() => toggleExpand(index)}>
                                  {expanded[index] ? 'Read less' : 'Read more'}
                                </Link>
                            )}
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Card>
                  </Accordion>
                </div>
               ))
            ):(
              <div id="content-wrapper" className="d-flex flex-column">
                <div className=" mb-4">
                  <div className="card-body pt-0">
                    <div className="table-responsive">
                    <table
                        className="table table-bordered"
                        id="dataTable"
                        width="100%"
                        cellspacing="0"
                        >
                        <thead>
                          <tr>
                            <th>Sr. no.</th>
                            <th>Company</th>
                            <th>Founder/CEO</th>
                            <th>Stage</th>
                            <th>Sector</th>
                            <th>Status</th>
                          </tr>
                        </thead>

                        <tbody>
                          {interest.map((items, index) => (
                            <tr key={index}>
                              <td>{index +1}</td>
                              <td className="admin_user_list_date"> {items?.company_data?.company_name} </td>
                              <td className="admin_user_list_date"> {items?.company_data?.company_founder_name?`${items.company_data.company_founder_name} (Founder)`:`${items?.company_data?.company_ceo_name}(CEO)`} </td>
                              <td className="admin_user_list_date"> {items?.company_data?.company_stage} </td>
                              <td className="admin_user_list_date"> {items?.company_data?.company_sector} </td>
                              <td>
                                <div className="profile_submit_btn">
                                  {items?.status==='P' && ( <p className='text-warning'>Pending</p> )}
                                  {items?.status==='A' && ( <p className="text-success">Accepted</p> )}
                                  {items?.status==='R' && ( <p className="text-danger">Declined</p> )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )

             ): isLoading ? (<>
              <div className="companies_section_main_div mb-3">
                <div className="d-flex align-items-center justify-content-between companies_select_all_div">
                  <Skeleton avatar />
                </div>
              </div>

              <div className="companies_section_main_div mb-3">
                <div className="d-flex align-items-center justify-content-between companies_select_all_div">
                  <Skeleton avatar />
                </div>
              </div>

              <div className="companies_section_main_div mb-3">
                <div className="d-flex align-items-center justify-content-between companies_select_all_div">
                  <Skeleton avatar />
                </div>
              </div>

              <div className="companies_section_main_div mb-3">
                <div className="d-flex align-items-center justify-content-between companies_select_all_div">
                  <Skeleton avatar />
                </div>
              </div>
            </>):(<NoDataFound/>)}

        </div>
      </div>
    </React.Fragment>
  );
}

export default List;
