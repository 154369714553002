import React from "react";
import { FaPen } from "react-icons/fa6";
import "../../Investor/Home/homePage.css";
import { RxCross2 } from "react-icons/rx";
import Portfoliosidebar from "../Sidebar/Portfoliosidebar";
import TopNavbar from "../../Investor/Navabr/navbar";

function EditPortfolioProfile() {
  return (
    <>
      <TopNavbar></TopNavbar>
      <div className="main-wrapper">
        <div>
          <Portfoliosidebar></Portfoliosidebar>
        </div>
        <div className="main_contant">
          <div className="container-fluid py-5 all_page_for_top_heading">
            <h2 className=" text-uppercase all_heading">Profile</h2>
            <div className="">
              <h4 className="profile_info_heading">Profile Info</h4>
              <div className="d-flex align-items-center home_beepkart_fa_pen">
                <img
                  src="/charaiteImage/BeepKart.png"
                  alt="Logo"
                  className="profile-logo"
                />
                <div className="position-relative">
                  <button className="btn btn-sm rounded-circle edit-btn home_fa_pen_button">
                    <FaPen />
                  </button>
                </div>
              </div>
              <form>
                <div className="row">
                  <div className="invest_controls">
                    <p >Investor Company’s Name </p>
                  </div>
                  <div className="col-md-4 mb-2">
                    <input
                      type="text"
                      placeholder="Founder Name "
                      className="form-control all_input"
                    />
                  </div>
                  <div className="col-md-4 mb-2">
                    <input
                      type="text"
                      placeholder="CEO Name"
                      className="form-control all_input"
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <select   className="form-control all_input select_option">
                    <option>Stage</option>
                    <option>Stage-first</option>
                    <option>Stage-second</option>

                    </select>
                  </div>
                </div>
                <div className="row expert_consumer" >
                  <div className="col-md-3">
                  <select   className="all_input select_option">
                    <option>Stage</option>
                    <option>Stage-first</option>
                    <option>Stage-second</option>

                    </select>
                  </div>
                  <div className="col-md-9">
                    <ul className="">
                      <li>
                        <p>Consumer Expert</p>
                        <span><RxCross2 /></span>
                     
                      </li>
                      <li>  <p>B2B </p>
                      <span><RxCross2 /></span></li>
                      <li>   <p>B2C </p>
                      <span><RxCross2 /></span></li>
                    </ul>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8 mb-3">
                    <input
                      type="email"
                      placeholder="Email Address"
                      className="form-control all_input"
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <input
                      type="tel"
                      placeholder="Mobile Number"
                      className="form-control all_input"
                    />
                  </div>
                </div>
              
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <textarea
                      className="form-control all_input_text_area"
                      placeholder="Description"
                      rows="4"
                    ></textarea>
                  </div>
                </div>
                <div className="profile_submit_btn">
                  <button type="button">Edit Profile</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditPortfolioProfile;
