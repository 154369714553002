import  { API_BASE_URL }  from './../config/constants';
import {getRequest, postRequest} from './API';

/*********************************************************
* Function Name : sentFaqEmail
* Description   : This function is used for get meetings list
* By            : Afsar Ali
* Date          : 23-08-2024 
*********************************************************/
export const sentFaqEmail = async (options) =>{
    try {
        const params = {
            url : `${API_BASE_URL}/common/sent-help-mail`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result, count : res?.data?.response?.count };
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/***** This function is used for generate random coloe ******* */
export const  getRandomColor=()=> {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
} //End of function
/**** This function is used for generate Letter profile image **** */
export const createImageFromInitials = (size, name, color) => {
    if (name == null) return;
    // name=getInitials(name)
    name=name.toUpperCase();

    const canvas=document.createElement('canvas')
    const context=canvas.getContext('2d')
    // canvas.width=canvas.height=size
    const width = 150;
    const height = 46;
    canvas.width = width;
    canvas.height = height;

    // Draw background
    context.fillStyle = "#ededed";
    context.fillRect(0, 0, width, height);

    context.fillStyle = `${color}50`;
    context.fillRect(0, 0, width, height);

    // Set text styles
    context.fillStyle = '#1e1e1e';
    context.textBaseline = 'middle';
    context.textAlign = 'center';
    context.font = `${height / 2}px Roboto`; // Font size is half of height

    // Calculate text position
    context.fillText(name, width / 2, height / 2);

    return canvas.toDataURL()
};
const getInitials = (name) => {
    let initials;
    const nameSplit = name.split(" ");
    const nameLength = nameSplit.length;
    if (nameLength > 1) {
        initials =
            nameSplit[0].substring(0, 1) +
            nameSplit[nameLength - 1].substring(0, 1);
    } else if (nameLength === 1) {
        initials = nameSplit[0].substring(0, 1);
    } else return;

    return initials.toUpperCase();
};
