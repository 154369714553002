import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./interest.css";
import { notification } from "antd";
import {
  InboxOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import {changeStatus} from '../../../controllers/interest/interestController'

const DeclinePopup = ({show, handleIsSelectShow, interestId, setInterestId}) => {
  const handleCloseModal = () => {
    setInterestId('')
    handleIsSelectShow();
  }

  const handleSubmit = async () =>{
    try {
      if(!interestId){
        notification.open({
          message: "Opps!",
          description: `Data not found.`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
        handleCloseModal();
      } else {
        const data = {
          interest_id : interestId,
          status : "R"
        }
        const res = await changeStatus(data);
        if(res.status === true){
          notification.open({
            message: "Success",
            description: `Interest accepted successfully.`,
            placement: "topRight",
            icon: <CheckCircleOutlined style={{ color: "green" }} />,
            duration: 2,
          });
        } else {
          notification.open({
            message: "Oops!!",
            description: `${res.message}`,
            placement: "topRight",
            icon: <InfoCircleOutlined style={{ color: "red" }} />,
            duration: 2,
          });
        }
        handleCloseModal();
      }
    } catch (error) {
      
    }
  }
  
  return (
    <div
      className="modal fade show"
      style={{ display: `${show?'block':'none'}`, backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: "400px" }}
      >
        <div className="modal-content">
          <div className="modal-body text-center p-4 login_popup_modal">
            <h5 className="mb-4">Are you Sure! <br /></h5>
            <div className="companies_popup_all_section">
              {/* <div className="d-flex justify-content-center mb-3 ">
                <div className="form-check me-4 companies_popup_radio_btn" onClick={()=>setPriority(1)}>
                  <input
                    className="form-check-input all_radio_btn"
                    type="radio"
                    name="priority"
                    id="priority1"
                    checked={priority===1?true:false}
                    value="1"
                  />
                  <label className="form-check-label" htmlFor="priority1">
                    Priority 1
                  </label>
                </div>
                <div className="form-check companies_popup_radio_btn" onClick={()=>setPriority(2)}>
                  <input
                    className="form-check-input all_radio_btn"
                    type="radio"
                    name="priority"
                    id="priority2"
                    value="2"
                    checked={priority===2?true:false}
                  />
                  <label className="form-check-label" htmlFor="priority2">
                    Priority 2
                  </label>
                </div>
              </div> */}
              <button type="button" className="btn btn-outline-danger mt-2 mb-4" onClick={handleSubmit} > Yes </button> 
              {"            "}
              <button type="button" className="btn btn-outline-danger mt-2 mb-4" onClick={handleCloseModal} > NO </button>
            </div>
          </div>
          <div className="position-absolute user_login_top_right_cross_btn">
            <button className="top-0 end-0 m-3" onClick={handleCloseModal}>
              x
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeclinePopup;
