import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { LuUsers2 } from "react-icons/lu";
import { notification, Flex, Spin } from "antd";
import { CiGrid41 } from "react-icons/ci";
import { FaRegUser } from "react-icons/fa";
import { HiOutlineUsers } from "react-icons/hi2";
import { IoHelpCircleOutline } from "react-icons/io5";
import { CiCalendar } from "react-icons/ci";
import { BsChatText } from "react-icons/bs";
import {
  FaHome,
  FaUser,
  FaBuilding,
  FaCalendarAlt,
  FaCommentDots,
  FaQuestionCircle,
  FaSignOutAlt,
} from "react-icons/fa";
import {
  InboxOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import "../../Investor/Sidebar/sidebar.css";

import {logout} from '../../../controllers/company/accounts';
import { sentFaqEmail } from "../../../controllers/common";
const Portfoliosidebar = ({ isSidebarOpen }) => {
  const navigate = useNavigate();
  const USERDATA = JSON.parse(sessionStorage.getItem('USER-INFO'));
  const [showHelp, setShowHelp] = useState(false);
  const toggaleShowHelp = () => setShowHelp(!showHelp);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState('');
  const handleChange = (e) =>{
    try {
      const {name, value} = e.target;
      setFormData((pre)=>({
        ...pre,
        [name] : value
      }))
    } catch (error) {
      
    }
  }
  const handleLogout = async () => {
    try {
      setIsLoading(true);
      await logout();
      setIsLoading(false);
      navigate('/company-login');
    } catch (error) {
      setIsLoading(false);
    }
  }

  const handleFaqSubmit = async () => {
    try {
      setIsLoading(true);
      const {message} = formData;
      if(!message){
        notification.open({
          message: "Oops!",
          description: `Message is required`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      } else{
        const options = {
          email : `${USERDATA?.company_email}`,
          name : `${USERDATA?.company_name}`,
          message : message
        }
        const res = await sentFaqEmail(options);
        if(res.status === true){
          notification.open({
            message: "Your message has been sent!",
            description: `Expect a revert shortly.`,
            placement: "topRight",
            icon: <CheckCircleOutlined style={{ color: "green" }} />,
            duration: 3,
          });
          setFormData(null);
          toggaleShowHelp();
        } else{
          notification.open({
            message: "Oops!",
            description: `${res?.message}`,
            placement: "topRight",
            icon: <InfoCircleOutlined style={{ color: "red" }} />,
            duration: 2,
          });
        }
      }
      setIsLoading(false);
    } catch (error) {
      notification.open({
        message: "Oops!",
        description: `Please try after some time.`,
        placement: "topRight",
        icon: <InfoCircleOutlined style={{ color: "red" }} />,
        duration: 2,
      });
    }
  }
  return (
    <>
      <div
        className={`sidebar bg-dark text-light d-flex flex-column align-items-center sidebar_main_content p-3 ${isSidebarOpen ? "visible" : "hidden"} ${USERDATA?.first_time_login?'blur':''}`}
      >
        <NavLink to="/company/dashboard" className="mb-4 text-center text-decoration-none">
          {({ isActive }) => (
            <div className="d-flex flex-column align-items-center">
              <div
                className={`p-2 rounded side_bar_all_svg_logo ${
                  isActive ? "" : "bg-secondary"
                }`}
                style={isActive ? { backgroundColor: "#a32532" } : {}}
              >
                <CiGrid41 className="text-light" size={24} />
              </div>
              <small className="side_bar_all_heading">Home</small>
            </div>
          )}
        </NavLink>

        <NavLink to="/company/profile" className="mb-4 text-center text-decoration-none">
          {({ isActive }) => (
            <div className="d-flex flex-column align-items-center">
              <div
                className={`p-2 rounded side_bar_all_svg_logo ${
                  isActive ? "" : "bg-secondary"
                }`}
                style={isActive ? { backgroundColor: "#a32532" } : {}}
              >
                <FaRegUser className="text-light" size={20} />
              </div>
              <small className="side_bar_all_heading">Profile</small>
            </div>
          )}
        </NavLink>

        <NavLink
          to="/company/interest"
          className="mb-4 text-center text-decoration-none"
        >
          {({ isActive }) => (
            <div className="d-flex flex-column align-items-center">
              <div
                className={`p-2 rounded side_bar_all_svg_logo ${
                  isActive ? "" : "bg-secondary"
                }`}
                style={isActive ? { backgroundColor: "#a32532" } : {}}
              >
                <HiOutlineUsers className="text-light" size={24} />
              </div>
              <small className="side_bar_all_heading">Interest</small>
            </div>
          )}
        </NavLink>

        <NavLink to="/company/meetings" className="mb-4 text-center text-decoration-none">
          {({ isActive }) => (
            <div className="d-flex flex-column align-items-center">
              <div
                className={`p-2 rounded side_bar_all_svg_logo ${
                  isActive ? "" : "bg-secondary"
                }`}
                style={isActive ? { backgroundColor: "#a32532" } : {}}
              >
                <CiCalendar className="text-light" size={24} />
              </div>
              <small className="side_bar_all_heading">Meetings</small>
            </div>
          )}
        </NavLink>

        <NavLink
          to="/company/feedbacks"
          className="mb-4 text-center text-decoration-none"
        >
          {({ isActive }) => (
            <div className="d-flex flex-column align-items-center">
              <div
                className={`p-2 rounded side_bar_all_svg_logo ${
                  isActive ? "" : "bg-secondary"
                }`}
                style={isActive ? { backgroundColor: "#a32532" } : {}}
              >
                <BsChatText  className="text-light" size={24} />
              </div>
              <small className="side_bar_all_heading">Feedbacks</small>
            </div>
          )}
        </NavLink>

        <NavLink
          to="javaScript:void(0)"
          onClick={toggaleShowHelp}
          className="mb-3 text-center text-decoration-none"
        >
          {({ isActive }) => (
            <div className="d-flex flex-column align-items-center">
              <div
                className={`p-2 rounded side_bar_all_svg_logo ${
                  isActive ? "" : "bg-secondary"
                }`}
                style={isActive ? { backgroundColor: "#a32532" } : {}}
              >
                <IoHelpCircleOutline  className="text-light" size={24} />
              </div>
              <small className="side_bar_all_heading">Help</small>
            </div>
          )}
        </NavLink>

        {/* <NavLink to='javaScript:void(0)' onClick={handleLogout} className="text-center text-decoration-none">
          {({ isActive }) => (
            <div className="d-flex flex-column align-items-center">
              <div
                className={`p-2 rounded side_bar_all_svg_logo ${
                  isActive ? "" : "bg-secondary"
                }`}
                style={isActive ? { backgroundColor: "#a32532" } : {}}
              >
                <FaSignOutAlt className="text-light" size={24} />
              </div>
              <small className="side_bar_all_heading">Logout</small>
            </div>
          )}
        </NavLink> */}
        <Flex align="center" gap="middle">
          <Spin 
              size="large" 
              spinning={isLoading}
              fullscreen={true}
          />
        </Flex>
      </div>

        <div
          className="modal fade show"
          style={{ display: `${showHelp?'block':'none'}`, backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div
            className="modal-dialog modal-dialog-centered"
            style={{ maxWidth: "400px" }}
          >
            <div className="modal-content">
              <div className="modal-body text-center p-4 login_popup_modal">
                <h5 className="mb-4  scan_the_qr_code">
                How may I assist you?
                </h5>
                <form>
                  <div className="row">
                    <div className="col-md-12 mb-12">
                      <input
                        type="text"
                        value="To : Chiratae Spotlight"
                        className="form-control all_input"
                        disabled
                      />
                    </div>
                  </div>
                  <br/>
                  <div className="row">
                    <div className="col-md-12 mb-12">
                      <textarea 
                        name="message"
                        id="message"
                        value={formData?.message}
                        onChange={handleChange}
                        placeholder="Please write your message..."
                        className="form-control all_input"
                        rows={6}
                        >
                      </textarea>
                    </div>
                  </div>
                  <br/>
                  <div className="profile_submit_btn">
                      <button type="button" 
                      onClick={handleFaqSubmit} 
                      >Submit</button>
                  </div>
                </form>
              </div>
              <div className="position-absolute user_login_top_right_cross_btn">
                <button className="top-0 end-0 m-3" onClick={toggaleShowHelp}> x </button>
              </div>
            </div>
          </div>
          <Flex align="center" gap="middle">
            <Spin 
                size="large" 
                spinning={isLoading}
                fullscreen={true}
            />
          </Flex>
        </div>
    </>
  );
}

export default Portfoliosidebar;
