import React, { useEffect, useState } from "react";
import{ notification, Flex, Spin } from 'antd';
import { RxCrossCircled } from "react-icons/rx";
import "../login.css";
import {
  InboxOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import {login, verifyOTP} from '../../../../controllers/company/accounts';
const LoginOtpVerification = ({isVerifyOTP, handleVerityOTPPopup, loginEmail}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const USERDATA = JSON.parse(sessionStorage.getItem("ADMIN-INFO"));
  const TOKEN = sessionStorage.getItem("TOKEN");
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenModal = () => setIsOpen(true);
  const handleCloseModal = () => {
    setIsOpen(false);
    handleVerityOTPPopup();
  }

  const[email, setEmail] =useState('');
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [isLoading, setIsLoading] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [isCounting, setIsCounting] = useState(true);

  const handleChange = (element, index) => {
    const value = element.value;
    const newOtp = [...otp];

    if (value === "") {
      newOtp[index] = "";
      setOtp(newOtp);
      if (element.previousSibling) {
        element.previousSibling.focus();
      }
    } else {
      if (isNaN(value)) return;

      newOtp[index] = value;
      setOtp(newOtp);
      
      if (element.nextSibling) {
        element.nextSibling.focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index !== 0) {
      e.preventDefault();
      const newOtp = [...otp];
      newOtp[index - 1] = "";
      setOtp(newOtp);
      e.target.previousSibling.focus();
    } else if(e.key === 'Enter'){
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const otpCode = otp.join('');
      if(!otpCode || !email){
        notification.open({
          message: "Opps!",
          description: `Your one-time password has expired or is incorrect. Please try again.`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      } else{
        const options = {
          email : email,
          otp : otpCode
        }
        const res = await verifyOTP(options);
        if(res.status === true){
          if(res?.result?.first_time_login === true){
            navigate('/company/profile');
            notification.open({
              message: "Success",
              description: `Welcome ${res?.result.company_name}`,
              placement: "topRight",
              icon: <CheckCircleOutlined style={{ color: "green" }} />,
              duration: 2,
            });
          } else{
            notification.open({
              message: "Success",
              description: `Welcome back! ${res?.result.company_name}`,
              placement: "topRight",
              icon: <CheckCircleOutlined style={{ color: "green" }} />,
              duration: 2,
            });
            navigate('/company/dashboard');  
          }
        }else{
          notification.open({
            message: "Opps!",
            description: `${res.message}`,
            placement: "topRight",
            icon: <InfoCircleOutlined style={{ color: "red" }} />,
            duration: 2,
          });
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const resentOTP = async () =>{
    try {
      const options = { email : email };
      const res = await login(options);
      if(res.status === true){
        notification.open({
          message: "Success",
          description: `${res?.message}`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        });
        setCountdown(60);
        setIsCounting(true);
      } else{
        notification.open({
          message: "Opps!",
          description: `${res?.message}`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }
    } catch (error) {
      
    }
  }

  useEffect(()=>{
    setEmail(loginEmail);
    setOtp(new Array(6).fill(""));
    setCountdown(60);
    setIsCounting(true);
  },[isVerifyOTP]);


  /*************************************************************
   * Purposs        : This hooks is used for handle otp sent countdown
   * Created Date   : 08-01-2024
   *************************************************************/
  useEffect(() => {
    if (USERDATA || TOKEN) {
      navigate("/");
    }
    let countdownInterval;
    if (isCounting) {
      countdownInterval = setInterval(() => {
        if (countdown > 0) {
          setCountdown((prevCountdown) => prevCountdown - 1);
        } else {
          clearInterval(countdownInterval);
          setIsCounting(false);
        }
      }, 1000);
    } else {
      clearInterval(countdownInterval);
    }
    return () => {
      clearInterval(countdownInterval);
    };
  }, [countdown, isCounting]);


  return (
    <div
      className="modal fade show"
      style={{ display: `${isVerifyOTP===true?"block":'none'}`, backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: "400px" }}
      >
        <div className="modal-content">
          <div className="modal-body text-center p-4 login_popup_modal">
            <img
              src="../charaiteImage/chirate_logo.png"
              alt="Logo"
              className="mb-4"
            />
            <h5 className="mb-3 login_your_account">OTP Verification</h5>
            <p className="mb-4 enter_digit_no_heading">
              Enter the 6-digit code that was sent to your phone number /
              email address.
            </p>
            <div className="otp-input-container mb-4 d-flex justify-content-between login_email_mobile_no ">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleChange(e.target, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  placeholder="X"
                  className="form-control otp-input mx-1 text-center"
                  style={{
                    width: "40px",
                    height: "40px",
                    backgroundColor: "#f0f0f0",
                    borderRadius: "10px",
                  }}
                />
              ))}
            </div>
            <button
              type="button"
              onClick={handleSubmit}
              className="btn btn-outline-danger"
              style={{
                borderRadius: "30px",
                width: "50%",
                padding: " 4px 0px 10px",
              }}
            >
              Submit
            </button> <br></br>
            {isCounting ? (
              <button
              type="button"
              className="btn btn-outline-secondary mt-3 mb-4 login_user_"
              style={{
                borderRadius: "30px",
                width: "75%",
                padding: " 4px 0px 10px",
              }}
              disabled={true}
            >
              {`Resend OTP in ${countdown ? countdown : 0}`}
            </button>
              
            ):(
              <button
                type="button"
                onClick={resentOTP}
                className="btn btn-outline-secondary mt-3 mb-4 login_user_"
                style={{
                  borderRadius: "30px",
                  width: "57%",
                  padding: "6px 0px 10px",
                }}
              >
                Resend Code
              </button>
            )}
           
          </div>
          
          <div className="position-absolute user_login_top_right_cross_btn">
            <button className="top-0 end-0 m-3" onClick={handleCloseModal}>
            <RxCrossCircled />
            </button>
          </div>
        </div>
      </div>
      <Flex align="center" gap="middle">
        <Spin 
            size="large" 
            spinning={isLoading}
            fullscreen={true}
        />
      </Flex>
    </div>
  );
}

export default LoginOtpVerification;
