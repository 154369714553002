import { useEffect, useState, useRef } from "react";
import { Accordion, Card } from "react-bootstrap";
import Sidebar from "../Sidebar/sidebar";
import TopNavbar from "../Navabr/navbar";
import "./companies.css";
import {list} from '../../../controllers/company/accounts';
import { FaTh, FaServer } from "react-icons/fa";
import { Skeleton } from "antd";
import { Link } from "react-router-dom";

import SelectPopUp from '../Compinies/Popup';
import { NOIMAGE } from "../../../config/constants";
import { createImageFromInitials, getRandomColor } from "../../../controllers/common";

const Companies = () => {
  const targetRef = useRef();
  const interestedCompanyList = JSON.parse(sessionStorage.getItem('INTEREST-DATA'));
  const [activeAccordion, setActiveAccordion] = useState(0);
  const handleAccordionClick = (eventKey) => {
    setActiveAccordion(activeAccordion === eventKey ? null : eventKey);
  };
  const [dataView, setDataView]= useState('grid');
  const [isSelectShow, setIsSelectShow]= useState(false);
  const handleIsSelectShow =() => setIsSelectShow(!isSelectShow);
  const [companyId, setCompanyId] = useState('');
  const handleSelect=(id)=>{ 
    setCompanyId(id);
    handleIsSelectShow();
  }

  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [TOTALCOUNT, setTotalCount] = useState();
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [expanded, setExpanded] = useState({});

  const toggleExpand = (index) => {
    setExpanded((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleSearchChnage = (e) =>{
    const {value} = e.target;
    if(e.key === 'Enter'){
      handleSearch(e);
    }else{
      setSearchText(value);
    }
  }
  const handleSearch = (e) => {
    e.preventDefault();
    // if(searchText.length > 0){
      setListData([]);
      setSkip(0);
      setIsSearch(!isSearch);
    // }
  }

  const getList = async () =>{
    try {
      const options = {
        type: "",
        condition: { 
          ...(searchText?{
            $or : [
              { company_name :  { $regex: searchText, $options: "i" } },
              { company_founder_name : { $regex: searchText, $options: "i" } },
              { company_stage : { $regex: searchText, $options: "i" } },
              { company_sector : { $regex: searchText, $options: "i" } }
            ],
          }:null),
          status: 'A' 
        },
        select: {},
        sort: { _id: -1 },
        // skip: SKIP ? SKIP : 0,
        // limit: LIMIT ? LIMIT : 10,
      }
      const res = await list(options);
      if(res.status === true){
        setListData(res.result);
        if(SKIP === 0){
          setTotalPages(res?.count || 0);
          setTotalCount(res?.count || 0);
        }
      }
    } catch (error) {
      
    }
  }

  const loadMoreData = async () => {
    try {
      const skip = ALLLISTDATA.length;
      const options = {
        type: "",
        condition: { 
          ...(searchText?{
            $or : [
              { company_name :  { $regex: searchText, $options: "i" } },
              { company_founder_name : { $regex: searchText, $options: "i" } },
              { company_stage : { $regex: searchText, $options: "i" } },
              { company_sector : { $regex: searchText, $options: "i" } }
            ],
          }:null),
          status: 'A'
        },
        select: {},
        sort: { _id: -1 },
        skip: skip ? skip : 0,
        limit: 10,
      }
      const res = await list(options);
      if(res?.status === true || res?.result?.length > 0){
        setListData((prev) => [...prev, ...res.result]);
      }
    } catch (error) {
      
    }
  }

  useEffect(()=>{
    getList();
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
    document.title = "Chiratae Ventures | Spotlight | Companies list";
  },[isSearch]);



  const lastItemRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if(TOTALCOUNT !== ALLLISTDATA.length){
            // loadMoreData();
          }
          // Optionally disconnect the observer if you only need the alert once
          observer.disconnect();
        }
      },
      { threshold: 1.0 } // Adjust threshold as needed
    );

    if (lastItemRef.current) {
      observer.observe(lastItemRef.current);
    }

    return () => {
      if (lastItemRef.current) {
        observer.unobserve(lastItemRef.current);
      }
    };
  }, [ALLLISTDATA,lastItemRef]);

  return (
    <>
      <TopNavbar></TopNavbar>
      <div className="main-wrapper" ref={targetRef}>
        <div>
          <Sidebar></Sidebar>
        </div>
        <div className="main_contant">
          <div className="container-fluid all_page_for_top_heading">
            <h2 className=" text-uppercase all_heading">Companies</h2>
          </div>
          <div className="container-fluid all_page_for_top_heading">
            <form onSubmit={handleSearch}>
              <div className="row">
                <div className="col-sm-9 col-md-9 col-lg-9">
                  <input className="form-control all_input" type="text" name="search" id="search" onChange={handleSearchChnage} value={searchText} placeholder="Search interest..."/>
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2">
                  <div className="profile_submit_btn">
                    <button type="submit" id="selected">Search</button>
                  </div>
                </div>
                <div className="col-sm-1 col-md-1 col-lg-1">
                  {dataView === 'table' ? (
                    <div title="Grid view"  className="profile_submit_btn" onClick={()=>setDataView('grid')}>
                      <button type="button" id="selected"><FaTh/></button>
                    </div>
                  ) : (
                    <div title="Table view" className="profile_submit_btn" onClick={()=>setDataView('table')}>
                      <button type="button" id="selected"><FaServer/></button>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
          {ALLLISTDATA?.length > 0 ? 
            dataView ==='grid'?(
              ALLLISTDATA.map((items, index) => (
                <div key={index} className="container-fluid companies_section_main_div mb-3" ref={index === ALLLISTDATA.length - 1 ? lastItemRef : null}>
                  <div className="d-flex align-items-center justify-content-between companies_select_all_div">
                    <div className="d-flex align-items-center companies_beep_cart_img">
                      {items?.company_image?(
                        <img
                          src={items?.company_image}
                          alt="Company Logo"
                          className="rounded"
                          style={{ marginRight: "10px" }}
                        />
                      ):(
                        <img
                          src={createImageFromInitials(100, `${items?.company_name.length > 8?items.company_name.substring(0,8)+'..':items?.company_name}`, '#ededed')}
                          alt="Company Logo"
                          className="rounded"
                          style={{ marginRight: "10px"}}
                        />
                      )}
                      <div>
                        <h6 className="mb-0" style={{ fontSize: "16px" }}>
                          {items?.company_name}
                        </h6>
                        <small className="text-muted">{items?.company_sector} </small>
                      </div>
                    </div>
                      {interestedCompanyList?.includes(items._id) ? (
                        // <button type="button" >Interest sent</button>
                        <div className="started_in_mint_ago">
                          <button type="button">Interest sent</button>
                        </div>
                      ):(
                        <div className="profile_submit_btn">
                          <button type="button" onClick={()=>handleSelect(items._id)}>Select</button>
                        </div>
                      )}
                  </div>
                  <Accordion
                    activeKey={activeAccordion}
                    onSelect={handleAccordionClick}
                    className="companies_accrodian"
                  >
                    <Card style={{ border: "1px solid #e0e0e0", borderRadius: "8px" }} >
                      <Accordion.Item eventKey={index} className="company_accodian_item">
                        <Accordion.Header>
                          <span>About the company</span>
                        </Accordion.Header>
                        <Accordion.Body
                          style={{ padding: "1rem" }}
                          className="companies_border_top"
                        >
                          <p> Company Name : <span>{items?.company_name}</span> </p>
                          <p> Founder Name : <span>{items?.company_founder_name}</span> </p>
                          <p> CEO Name : <span>{items?.company_ceo_name}</span> </p>
                          <p> Stage : <span> {items?.company_stage} Stage</span> </p>
                          <p> Sector : <span>{items?.company_sector}</span> </p>
                          <p> Description :{" "}
                            {expanded[index]?
                            <span> 
                              {`${items?.company_description} `}
                            </span> : <span>
                            {items?.company_description !== 'undefined' ?`${items.company_description.slice(0, 175)}`:``}
                            </span>}
                            {items?.company_description?.length > 175 && (
                              <Link type="button" onClick={() => toggleExpand(index)}>
                                {expanded[index] ? 'Read less' : 'Read more'}
                              </Link>
                            )}
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Card>
                  </Accordion>
                </div>
              ))
            ):(
              <div id="content-wrapper" className="d-flex flex-column">
                <div className=" mb-4">
                  <div className="card-body pt-0">
                    <div className="table-responsive">
                    <table
                        className="table table-bordered"
                        id="dataTable"
                        width="100%"
                        cellspacing="0"
                        >
                        <thead>
                          <tr>
                            <th>Sr. no.</th>
                            <th>Company</th>
                            <th>Founder/CEO</th>
                            <th>Stage</th>
                            <th>Sector</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {ALLLISTDATA.map((items, index) => (
                            <tr key={index} ref={index === ALLLISTDATA.length - 1 ? lastItemRef : null}>
                              <td>{index +1}</td>
                              <td className="admin_user_list_date"> {items?.company_name} </td>
                              <td className="admin_user_list_date"> {items?.company_founder_name?`${items.company_founder_name} (Founder)`:`${items?.company_ceo_name}(CEO)`} </td>
                              <td className="admin_user_list_date"> {items?.company_stage} </td>
                              <td className="admin_user_list_date"> {items?.company_sector} </td>
                              <td>
                                {interestedCompanyList?.includes(items._id) ? (
                                  <div className="started_in_mint_ago">
                                    <button type="button">Interest sent</button>
                                  </div>
                                ):(
                                  <div className="profile_submit_btn">
                                    <button type="button" onClick={()=>handleSelect(items._id)}>Select</button>
                                  </div>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ):(<>
            <div className="container-fluid companies_section_main_div mb-3">
              <div className="d-flex align-items-center justify-content-between companies_select_all_div">
                <Skeleton avatar/>
              </div>
            </div>
            <div className="container-fluid companies_section_main_div mb-3">
              <div className="d-flex align-items-center justify-content-between companies_select_all_div">
                <Skeleton avatar/>
              </div>
            </div>
            <div className="container-fluid companies_section_main_div mb-3">
              <div className="d-flex align-items-center justify-content-between companies_select_all_div">
                <Skeleton avatar/>
              </div>
            </div>
          </>)}
        </div>
      </div>
      <SelectPopUp 
        show={isSelectShow} 
        handleIsSelectShow={handleIsSelectShow} 
        companyId={companyId}
        setCompanyId={setCompanyId} />
    </>
  );
}

export default Companies;
