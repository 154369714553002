import React, { useEffect,useState, useRef } from "react";
import "../../Investor/Home/homePage.css";
import { FaUserCircle, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import { Skeleton } from "antd";
import HomeSlider from "./homeSlider";
import Meetingslider from "./Portfolioschdulemeet";
import { interestList } from '../../../controllers/interest/interestController';
import { meetingList } from "../../../controllers/meetings/meetingController";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CompanyCard from "../Skelton/CompanyCard";
import {notification} from 'antd'

import AcceptPopUp from '../Interest/Popup'
import DeclinePopUp from '../Interest/DeclinePopup'

import NoDataFound from './NoDataFound';


const Homeportfolio = () => {
  const sliderRef = useRef(null);
  const USERDATA = JSON.parse(sessionStorage.getItem('USER-INFO'));

  const [isSelectShow, setIsSelectShow]= useState(false);
  const handleIsSelectShow =() => setIsSelectShow(!isSelectShow);

  const [isDeclineShow, setIsDeclineShow]= useState(false);
  const handleIsDeclineShow =() => setIsDeclineShow(!isDeclineShow);

  const [isInterestloading, setInterestloading]= useState(true);
  const [isMeetingloading, setMeetingloading]= useState(true);
  const [interestId, setInterestId] = useState('');
  const [interest, setInterest] = useState('');

  const [meetingListData, setMeetingList] = useState('');
  
  const getInterestList = async () =>{
    try {
      const options = {
        condition : { status : 'P', company_data : USERDATA._id }
      }
      const res = await interestList(options);
      if(res.status === true){
        if (res.result.length === 1) {
          setInterest([res.result[0], res.result[0]]);
        } else {
          setInterest(res.result);
        }
      }
      setInterestloading(false);
    } catch (error) {
      setInterestloading(false);
      setInterest('');
    }
  }

  const handleAccept=(id)=>{ 
    setInterestId(id);
    handleIsSelectShow();
  }

  const handleDecline=(id)=>{ 
    setInterestId(id);
    handleIsDeclineShow();
  }

  useEffect(()=>{
    getInterestList();
    document.title = "Chiratae Ventures | Spotlight | Company Darhboard";
  },[isSelectShow, isDeclineShow]);


  const getMeetingList = async () => {
    setMeetingloading(true);
    try {
      const options = {
        type: "",
        condition: {  },
        select: {},
        sort: { _id: -1 },
        skip: 0,
        limit: 10,
      }
      const res = await meetingList(options);
      if(res.status === true){
        if (res.result.length === 1) {
          setMeetingList([res.result[0], res.result[0]]);
        } else {
          setMeetingList(res.result);
        }
      }
      setMeetingloading(false);
    } catch (error) {
      setMeetingList('');
      setMeetingloading(false);
    }
  }

  useEffect(()=>{
    getMeetingList();
  },[])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  
  useEffect(() => {
    const handleResize = () => {
      if (sliderRef.current) {
        sliderRef.current.slickGoTo(0);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  
  
  return (
    <React.Fragment>
      <div className="container-fluid py-3 all_page_for_top_heading">
        <h2 className=" text-uppercase all_heading">Dashboard</h2>
        <div className="">
          <h4 className="profile_info_heading">Profile info</h4>
        </div>
        <div className="row mb-2">
          <div className="col">
            <div className="card  mt-2 home_investor">
              <div className="row">
                <div className="col-md-4 d-flex align-items-center profile_investor_company_name">
                  <FaUserCircle className="me-2 " />
                  {USERDATA?.company_name ?<span>{USERDATA?.company_name}</span>:<Skeleton loading={true} paragraph={false}/>}
                  
                </div>
                <div className="col-md-4 d-flex align-items-center profile_investor_company_name">
                  <FaPhoneAlt className="me-2 " />
                  {USERDATA?.company_phone ?<span>{USERDATA?.company_phone}</span>:isMeetingloading?<Skeleton loading={true} paragraph={false}/>:'91XXXXXXXXX'}
                </div>
                <div className="col-md-4 d-flex align-items-center profile_investor_company_name">
                  <FaEnvelope className="me-2 " />
                  {USERDATA?.company_email ?<span>{USERDATA?.company_email}</span>:<Skeleton loading={true} paragraph={false} title={true}/>}
                </div>
              </div>
            </div>
          </div>
        </div>
        {meetingListData.length > 0 ? (
          <div className="row mb-4">
            <div className="col">
              <div className="mb-2 mt-2">
                <h4 className="profile_info_heading">Scheduled Meetings</h4>
              </div>
              {/* <div className="home_no_metting_to_show">
                <span className="text-danger">No meetings to show</span>
              </div> */}
              <Meetingslider meetingListData={meetingListData} />
            </div>
          </div>
        ):(isInterestloading?Array.from({ length : 5 }, (_, index) => ( <CompanyCard key={index} />)) : '')}

        {(!isInterestloading && interest.length > 0) ? (
          <div className="row">
            <div className="col home_new_company">
              <div>
                <h4 className="home_new_company_heading">New received interest</h4>
              </div>
              <div className="profile_submit_btn">
                <button type="button">View All</button>
              </div>
            </div>
          </div>
        ) : ''}
        
        <Slider ref={sliderRef} {...settings}>
          {interest?.length > 0 ? (
            interest.map((items, index) => (
              <div key={index}  className="px-2 ">
                <div className=" h-100 home_slider_card">
                  <div className="card-body d-flex flex-column home_slider_img_content">
                    {items?.investor_data?.investor_image ? (
                      <img
                        src={items?.investor_data?.investor_image}
                        alt="BonusHub"
                        className="mb-2"
                      />
                    ) : (
                      <Skeleton active paragraph={false} title={false} />
                    )}
                    
                    <h5 className="card-title">{items?.investor_data?.investor_firm_name?.length > 22 ?`${items?.investor_data?.investor_firm_name.substring(0,22)}...`:`${items?.investor_data?.investor_firm_name}`}</h5>
                    <p className="card-text">{items?.investor_data?.investor_first_name} {items?.investor_data?.investor_last_name?.length > 30 ?`${items?.investor_data?.investor_last_name.substring(0,12)}...`:`${items?.investor_data?.investor_last_name}`}</p>
                    <p className="card-text flex-grow-1 home_slider_bonus_hub">
                      {items?.investor_data?.investor_designation}
                    </p>
                    <div className="d-flex justify-content-between mt-4">
                    <div className="home_select">
                        <button type="button" onClick={()=>handleAccept(items?._id)}>Accept</button>
                      </div>
                      <div className="home_select">
                        <button type="button" onClick={()=>handleDecline(items?._id)}>Decline</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : isInterestloading?Array.from({ length : 5 }, (_, index) => ( <CompanyCard key={index} />)) : '' }
        </Slider>

        {(!isMeetingloading && !isInterestloading && interest?.length === 0 && meetingListData?.length === 0) && ( <NoDataFound/> )}
        
        <AcceptPopUp 
          show={isSelectShow} 
          handleIsSelectShow={handleIsSelectShow} 
          interestId={interestId}
          setInterestId={setInterestId} />

        <DeclinePopUp 
          show={isDeclineShow} 
          handleIsSelectShow={handleIsDeclineShow} 
          interestId={interestId}
          setInterestId={setInterestId} />
          
      </div>
    </React.Fragment>
  );
}

export default Homeportfolio;
