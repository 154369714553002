import React from "react";
import Portfoliosidebar from "../Sidebar/Portfoliosidebar";
import TopNavbar from "../../Investor/Navabr/navbar";
import "../../Investor/Reschedule Request/reScheduleRequest.css";
import { PiArrowArcLeftDuotone } from "react-icons/pi";

const CancelRequest = () => {
  return (
    <>
      <div className="main_contant">
        <div className="container-fluid py-5 all_page_for_top_heading">
          <h2 className=" text-uppercase all_heading">Cancel</h2>
          <div className="">
            <div className="d-flex align-items-center mb-3 mt-4 all_page_for_top_main_heading">
              <button className="re_schudule_svg">
                <PiArrowArcLeftDuotone />
              </button>
              <h3 className="m-0 re_schudule_request">Cancel Request</h3>
            </div>
            <p className="re_schudule_reason">
              Select your reschedule reason
            </p>
            <form>
              <div className="form-check re_schudule_form_check">
                <input
                  className="form-check-input all_radio_btn"
                  type="radio"
                  name="radioGroup"
                  id="option1"
                  value="option1"
                />
                <label className="form-check-label" htmlFor="option1">
                  It is a long established fact that a reader will be
                  distracted
                </label>
              </div>
              <div className="form-check re_schudule_form_check">
                <input
                  className="form-check-input all_radio_btn"
                  type="radio"
                  name="radioGroup"
                  id="option2"
                  value="option2"
                />
                <label className="form-check-label" htmlFor="option2">
                  It is a long established fact that a reader will be
                  distracted
                </label>
              </div>
              <div className="form-check re_schudule_form_check">
                <input
                  className="form-check-input all_radio_btn"
                  type="radio"
                  name="radioGroup"
                  id="option3"
                  value="option3"
                />
                <label className="form-check-label" htmlFor="option3">
                  It is a long established fact that a reader will be
                  distracted
                </label>
              </div>
              {/* Repeat as necessary */}
              <div className="form-check re_schudule_form_check">
                <input
                  className="form-check-input all_radio_btn"
                  type="radio"
                  name="radioGroup"
                  id="optionOther"
                  value="optionOther"
                />
                <label className="form-check-label" htmlFor="optionOther">
                  Other
                </label>
              </div>
              <div className="mb-3 re_schedule_textarea">
                <textarea
                  className="form-control mt-3"
                  placeholder="Enter your reason here......."
                  rows="4"
                ></textarea>
              </div>
              <div className="profile_submit_btn">
                <button type="button">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default CancelRequest;
