import React, { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { FaBell, FaUserCircle } from "react-icons/fa";
import { FiAlignJustify, FiX } from "react-icons/fi";
import Sidebar from "../Sidebar/sidebar";
import Portfoliosidebar from "../../Company/Sidebar/Portfoliosidebar";
import { GoBell } from "react-icons/go";
import { CiUser } from "react-icons/ci";
import { FaPowerOff } from "react-icons/fa";
import { Dropdown, Image, Badge } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import "./navbar.css";
import { useNavigate } from "react-router-dom";
import { logout as investorLogout } from "../../../controllers/investor/accounts";
import { logout as companyLogout } from "../../../controllers/company/accounts";
import { AiOutlineLogout } from "react-icons/ai";
import { Flex, Spin } from "antd";
const TopNavbar = () => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const USERDATA = JSON.parse(sessionStorage.getItem('USER-INFO'));

  const handleLogout = async () =>{
    try {
      if(USERDATA?.investor_email){
        await investorLogout();
        navigate('/');
      } else{
        await companyLogout();
        navigate('/');
      }
    } catch (error) {
      sessionStorage.clear();
      localStorage.clear();
      navigate('/');
    }
  }
  
  return (
    <div>
      <div className="chirate_header_main_section">
        <Navbar
          bg="light"
          className="px-4"
          style={{ width: "96%", margin: "0 auto", top: "10px" }}
          fixed="top"
        >
          <Navbar.Brand href="javaScript:void(0)" onClick={()=>navigate('/')} className="me-auto navbar_left_logo">
            <img
              src="/charaiteImage/navbar_img.png"
              alt="Logo"
              height="30"
              loading="lazy"
            />
          </Navbar.Brand>
         
          
          <Nav className="d-flex align-items-center">
          
            {/* //Notifaction baar */}
            {/* <Dropdown align="end" className="notification_dropdown">
              <Dropdown.Toggle variant="link" id="notification_dropdown">
                <GoBell className="bell-icon" />
                <span className="badge">1</span>
              </Dropdown.Toggle> 

              <Dropdown.Menu className="dropdown-menu">
                <div className="dropdown-arrow"></div>
                <div>
                  <div className="drop_down_notification">
                    <div className="notifications_header">Notifications</div>
                    <span className="navbar_recent_notication">
                      Recent Notifications
                    </span>
                  </div>
                  <Dropdown.Item
                    className="notification-item"
                    onClick={(e) => e.stopPropagation()} // Prevent dropdown from closing
                  >
                    <div className="notification_image_with_svg">
                      <img
                        src="/charaiteImage/notification_img.png"
                        alt="notification"
                      />
                      <div>
                        <div className="dropdown_eight_min">8 min ago...</div>
                        <div className="notification_title">
                          Quick Dashboard Data
                        </div>
                        <div className="notification-subtitle">
                          It is a long established fact...
                        </div>
                      </div>
                    </div>
                    <div className="notification_with_svg">
                      <BsThreeDotsVertical />
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="notification-item"
                    onClick={(e) => e.stopPropagation()} // Prevent dropdown from closing
                  >
                    <div className="notification_image_with_svg">
                      <img
                        src="/charaiteImage/notification_img.png"
                        alt="notification"
                      />
                      <div>
                        <div className="dropdown_eight_min">8 min ago...</div>
                        <div className="notification_title">
                          Quick Dashboard Data
                        </div>
                        <div className="notification-subtitle">
                          It is a long established fact...
                        </div>
                      </div>
                    </div>
                    <div className="notification_with_svg">
                      <BsThreeDotsVertical />
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="notification-item"
                    onClick={(e) => e.stopPropagation()} // Prevent dropdown from closing
                  >
                    <div className="notification_image_with_svg">
                      <img
                        src="/charaiteImage/notification_img.png"
                        alt="notification"
                      />
                      <div>
                        <div className="dropdown_eight_min">8 min ago...</div>
                        <div className="notification_title">
                          Quick Dashboard Data
                        </div>
                        <div className="notification-subtitle">
                          It is a long established fact...
                        </div>
                      </div>
                    </div>
                    <div className="notification_with_svg">
                      <BsThreeDotsVertical />
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="notification-item"
                    onClick={(e) => e.stopPropagation()} // Prevent dropdown from closing
                  >
                    <div className="notification_image_with_svg">
                      <img
                        src="/charaiteImage/notification_img.png"
                        alt="notification"
                      />
                      <div>
                        <div className="dropdown_eight_min">8 min ago...</div>
                        <div className="notification_title">
                          Quick Dashboard Data
                        </div>
                        <div className="notification-subtitle">
                          It is a long established fact...
                        </div>
                      </div>
                    </div>
                    <div className="notification_with_svg">
                      <BsThreeDotsVertical />
                    </div>
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>*/}
            {USERDATA?.company_name ? (
              <Nav.Link
                onClick={()=>navigate('/company/profile')}
                  href="javaScript:void(0)"
                  className="d-flex align-items-center ms-3 navbar_user_heading"
                >
                  <CiUser className="me-1" />
                  <h6> <span>{USERDATA?.company_name || USERDATA?.investor_first_name}</span> </h6>
              </Nav.Link>
            ):(
              <Nav.Link
                onClick={()=>navigate('/investor/profile')}
                  href="javaScript:void(0)"
                  className="d-flex align-items-center ms-3 navbar_user_heading"
                >
                  <CiUser className="me-1" />
                  <h6> <span>{USERDATA?.company_name || USERDATA?.investor_first_name}</span> </h6>
                </Nav.Link>
            )}
            <Nav.Link href="javaScript:void(0)" onClick={handleLogout}>
              <AiOutlineLogout  className="bell-icon" color="#a32532" />
            </Nav.Link>
            
          </Nav>
          <div className="block md:hidden navbar_hemburger_button">
            {isSidebarOpen ? (
              <FiX
                className="text-dark cursor-pointer text-2xl"
                onClick={toggleSidebar}
              />
            ) : (
              <FiAlignJustify
                className="text-dark cursor-pointer text-2xl"
                onClick={toggleSidebar}
              />
            )}
          </div>
        </Navbar>
      </div>

      {USERDATA?.investor_email?(
        <Sidebar isSidebarOpen={isSidebarOpen} />
      ):(
        <Portfoliosidebar isSidebarOpen={isSidebarOpen} />
      )}
    </div>
  );
}

export default TopNavbar;
