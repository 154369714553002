import React, { useEffect, useRef, useState } from "react";
import "./homePage.css";
import { FaUserCircle, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import HomeSlider from "./homeSlider";
import { Skeleton } from "antd";
import {list} from '../../../controllers/company/accounts';
import { useNavigate } from "react-router-dom";
import MeetingSlider from './Portfolioschdulemeet';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { meetingList } from '../../../controllers/meetings/meetingController';

const Home = () => {
  const navigate = useNavigate();
  const USERDATA = JSON.parse(sessionStorage.getItem('USER-INFO'));
  const [companyList, setCompamyList] = useState('');
  const [meetingListData, setMeetingList] = useState('');

  const getMeetingList = async () =>{
    try {
      const options = {
        type: "",
        condition: {  },
        select: {},
        sort: { _id: -1 },
        skip: 0,
        limit: 10,
      }
      const res = await meetingList(options);
      if(res.status === true){
        if (res.result.length === 1) {
          setMeetingList([res.result[0], res.result[0]]);
        } else {
          setMeetingList(res.result);
        }
      }
    } catch (error) {
      setMeetingList('');
    }
  }



  const getCompanyList = async () =>{
    try {
      const options = {
        condition : { status : 'A'}
      }
      const res = await list(options);
      if(res.status === true){
        setCompamyList(res.result);
      }
    } catch (error) {
      
    }
  }

  useEffect(()=>{
    getCompanyList();
    getMeetingList();
    document.title = "Chiratae Ventures | Spotlight | Home";
  },[]);


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    beforeChange: (current, next) => setActiveCard(next), // Update the active card
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const sliderRef = useRef(null);
  const [activeCard, setActiveCard] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (sliderRef.current) {
        sliderRef.current.slickGoTo(0);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCardClick = (index) => {
    setActiveCard(index);
  };
  
  return (
    <>
      <div className="container-fluid py-3 all_page_for_top_heading">
        <h2 className=" text-uppercase all_heading">Dashboard</h2>
        <div className="">
          <h4 className="profile_info_heading">Profile info</h4>
        </div>
        <div className="row mb-2">
          <div className="col">
            <div className="card  mt-2 home_investor">
              <div className="row">
                <div className="col-md-4 d-flex align-items-center profile_investor_company_name">
                  <FaUserCircle className="me-2 " />
                  {USERDATA?.investor_firm_name ?<span>{USERDATA?.investor_firm_name}</span>:<Skeleton loading={true} paragraph={false}/>}
                </div>
                <div className="col-md-4 d-flex align-items-center profile_investor_company_name">
                  <FaPhoneAlt className="me-2 " />
                  {USERDATA?.investor_phone ?<span>{USERDATA?.investor_phone}</span>:<Skeleton loading={true} paragraph={false}/>}
                </div>
                <div className="col-md-4 d-flex align-items-center profile_investor_company_name">
                  <FaEnvelope className="me-2 " />
                  {USERDATA?.investor_email ?<span>{USERDATA?.investor_email}</span>:<Skeleton loading={true} paragraph={false}/>}
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {meetingListData.length > 0 ?(
          <div className="row mb-4">
            <div className="col">
              <div className="mb-2 mt-2">
                <h4 className="profile_info_heading">Scheduled Meetings</h4>
              </div>
              <MeetingSlider meetingListData={meetingListData}/>
              {/* <div className="container mt-2 home_bottom_carousls">
                <Slider ref={sliderRef} {...settings}>
                  {[...Array(2).keys()].map((index) => (
                    <div key={index} className="px-2 ">
                      <div
                        className={`card custom-card mx-auto schedule_metting_slider_card ${
                          activeCard === index ? "active" : ""
                        }`}
                        onClick={() => handleCardClick(index)} // Update active card on click
                      >
                        <div className="row g-0 schedule_metting_row">
                          <div className="col-md-3 d-flex align-items-center justify-content-center">
                            <img
                              src={`/charaiteImage/schedule${index + 1}.png`}
                              alt="Aura Logo"
                              className="img-fluid logo"
                            />
                          </div>
                          <div className="col-md-9">
                            <div className="card-body schedule_metting_for_content">
                              <p className="card-text date-time">
                                3 Dec, 2024, at 02:00PM
                              </p>
                              <h5 className="card-title company-name">
                                Portfolio Company Name...
                              </h5>
                              <p className="card-text founder">
                                Mr. Brian Roberto (Founder)
                              </p>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between mb-3 schedule_metting_button">
                            <button type="button" className="priority">
                              Priority 1
                            </button>
                            <button type="button" className="table-number">
                              Table No: 45
                            </button>
                          </div>
                        </div>
                        <div className="card-footer text-center schedule_metting_in_min">
                          {activeCard === index
                            ? "Started 30 mins. ago"
                            : "In 30 mins."}
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div> */}


            </div>
          </div>
        ) : (
          <div className="row mb-4">
            <div className="col">
              <div className="mb-2 mt-2">
                <h4 className="profile_info_heading">Scheduled meetings</h4>
              </div>
              <div className="home_no_metting_to_show">
                <span className="text-danger">No meetings to show</span>
              </div>
            </div>
          </div>
        )}


        <div className="row">
          <div className="col home_new_company">
            <div>
              <h4 className="home_new_company_heading">New companies</h4>
            </div>
            <div className="profile_submit_btn">
              <button type="button" onClick={()=>navigate('/investor/companies')}>View all</button>
            </div>
          </div>
        </div>
        <HomeSlider list={companyList} />
      </div>
    </>
  );
}

export default Home;
