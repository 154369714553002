import Sidebar from "../Sidebar/sidebar";
import Home from "./home";
import TopNavbar from "../Navabr/navbar";
import { useEffect, useRef } from "react";
const HomePage = () => {
  const targetRef = useRef();
  useEffect(()=>{
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
    document.title = "Chiratae Ventures | Spotlight | Interest Dashboard";
  },[])
  return (
    <>
      <TopNavbar></TopNavbar>
      <div className="main-wrapper" ref={targetRef}>
        <div>
          <Sidebar></Sidebar>
        </div>
        <div className="main_contant">
          <Home></Home>
        </div>
      </div>
    </>
  );
};
export default HomePage;
