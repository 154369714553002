import Portfoliosidebar from "../Sidebar/Portfoliosidebar";
import Homeportfolio from "./home";
import TopNavbar from "../../Investor/Navabr/navbar";
import { useEffect, useRef } from "react";
const MainHomePage = () => {
  const targetRef = useRef();
  useEffect (()=>{
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  },);
  return (
    <>
      <TopNavbar></TopNavbar>
      <div className="main-wrapper" ref={targetRef}>
        <div>
          <Portfoliosidebar></Portfoliosidebar>
        </div>
        <div className="main_contant">
          <Homeportfolio></Homeportfolio>
        </div>
      </div>
    </>
  );
};
export default MainHomePage;
