import React from 'react';
import { Card, Skeleton } from 'antd';

const CompanyCard = () => {
  return (
    <div className="px-2">
        <div className="h-100 home_slider_card">
            <Card className="home_slider_img_content" bodyStyle={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Skeleton avatar className="mb-2" paragraph={false} title={false} />
                <Skeleton  className="card-title" active title={{width:"80%"}} paragraph={false} />
                <Skeleton  className="card-title" active title={{width:"100%"}} paragraph={false} />
                <Skeleton  className="card-title" active title={{width:"100%"}} paragraph={false} />
                <div className="d-flex justify-content-between mt-4">
                <Skeleton.Button active size="small" style={{ width: 100 }} />
                <Skeleton.Button active size="small" style={{ width: 100 }} />
                </div>
            </Card>
        </div>
    </div>
  );
};

export default CompanyCard;
