import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Login/login.css";

function FeedbackPopup() {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenModal = () => setIsOpen(true);
  const handleCloseModal = () => setIsOpen(false);

  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        onClick={handleOpenModal}
      >
        Open Login Popup
      </button>

      {isOpen && (
        <div
          className="modal fade show"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div
            className="modal-dialog modal-dialog-centered"
            style={{ maxWidth: "400px" }}
          >
            <div className="modal-content">
              <div className="modal-body text-center p-4 login_popup_modal">
                <h5 className="mb-4  scan_the_qr_code">
                  Scan the QR code and <br /> write your feedback
                </h5>
                 <div className="qr_code_img">
                    <img src="/charaiteImage/qr_code.png" alt="" />
                 </div>
              </div>
              <div className="position-absolute user_login_top_right_cross_btn">
                <button className="top-0 end-0 m-3" onClick={handleCloseModal}>
                  x
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FeedbackPopup;
