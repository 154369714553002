import "./style.css";
// import { FRONT_ASSETS_URL } from "../constant";
import { Link, useNavigate } from "react-router-dom";
import { FaRegEnvelope } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { IoMdCall } from "react-icons/io";
import { BsTwitterX } from "react-icons/bs";
import { FaFacebookF, FaYoutube, FaMediumM, FaRegNewspaper } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io5";
import { FaLinkedinIn } from "react-icons/fa";
import moment from "moment";
import InvestorLogin from './Investor/Login/investors/login';
import CompanyLogin from './Company/Login/company/login';
import { useEffect, useState } from "react";
const logo = "";
const Home = () => {
    const navigate = useNavigate();
    const USERDATA = JSON.parse(sessionStorage.getItem('USER-INFO'));
    const [investorPopup, setInvestorPopup] = useState(false);
    const handleInvestorPopup = () =>{
        setInvestorPopup(!investorPopup);
    }
    const [companyPopup, setCompanyPopup] = useState(false);
    const handleCompanyPopup = () =>{
        setCompanyPopup(!companyPopup);
    }
    const investorDashboard = () => {
        navigate('/investor/dashboard');
    }

    const companyDashboard = () => {
        navigate('/company/dashboard');
    }
    useEffect(()=>{
      document.title = "Chiratae Ventures | Spotlight ";
    },[])

  return (
    <>
    <div className="landing">

   
      <div className="header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 col-12 main_logo">
              <Link to="/"><img src={`./image/logo.png`} alt="logo" className="logo" /></Link>
              <div className="inner_section">
                <h1>Chiratae Ventures</h1>
                <h2>
                  India's Largest Home Grown <br></br>Venture Capital Fund.
                </h2>
                <p>
                  {/* It is a long established fact that a reader will be distract{" "}
                  <br></br>by the readable content of a page when looking at its{" "}
                  <br></br> layout. The point of using Lorem Ipsum. */}
                  Chiratae Spotlight is one of the largest platforms for follow-on funding{" "}
               designed to connect our portfolio companies with investors globally. As Chiratae's{" "}
                  flagship program, Spotlight fosters meaningful engagements between companies and{" "}
                investors, driving discovery and strong partnerships across various sectors.
                </p>
                {!USERDATA ? (
                    <div className="dashbaord_btn">
                        <button onClick={handleInvestorPopup}>Login as Investor</button>
                        <button onClick={handleCompanyPopup} className="login_btn">Login as Company</button>
                    </div>
                ): USERDATA?.investor_email?(
                    <div className="dashbaord_btn">
                        <button onClick={investorDashboard}>Go to dashboard</button>
                    </div>
                ):(
                    <div className="dashbaord_btn">
                        <button onClick={companyDashboard}>Go to dashboard</button>
                    </div>
                )}
              </div>
            </div>
            <div className="col-md-7 px-0 text-align col-12">
              {/* <img src={`./image/side_img.png`} alt="logo" className="img_fluid" /> */}
              <img src={`./image/chirataelandingimage.png`} alt="logo" className="img_fluid" />
            </div>


           
          </div>
          <div className="row copy_right">
          <div className="col-md-6 col-12">
                <p className="copy_text">Copyright © {moment().format('YYYY')} ® Chiratae Ventures | <Link to="https://www.chiratae.com/terms-and-conditions/" target="__blank" className="copy_text">Terms and Conditions Privacy Policy</Link></p>
              {/* <ul>
                    <li><Link to="/" target="__blank"><FaRegEnvelope className="icon"/></Link></li>
                    <li><Link to="/" target="__blank"><FaLocationDot  className="icon" /></Link></li>
                    <li><Link to="/" target="__blank"><IoMdCall className="icon" /></Link></li>
                </ul> */}
            </div>
            <div className="col-md-6 col-12">
              <ul className="right_side">
                <li><Link to="https://twitter.com/ChirataeVC" target="__blank"> <BsTwitterX className="icon" /></Link></li>
                <li><Link to="https://www.linkedin.com/company/chiratae-ventures/" target="__blank"> <FaLinkedinIn className="icon" /></Link></li>
                <li><Link to="https://www.youtube.com/channel/UCS5VqCTe-NjUG2fEyov5k6g" target="__blank"> <FaYoutube className="icon" /> </Link></li>
                <li><Link to="https://medium.com/chiratae-ventures/" target="__blank"> <FaMediumM className="icon" /> </Link></li>
              </ul>
            </div>

          </div>
        </div>
      </div>
      <InvestorLogin investorPopup={investorPopup} handleInvestorPopup={handleInvestorPopup} />
      <CompanyLogin companyPopup={companyPopup} handleCompanyPopup={handleCompanyPopup}  />
      </div>
    </>
  );
};

export default Home;
