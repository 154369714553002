import { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import Sidebar from "../Sidebar/sidebar";
import TopNavbar from "../Navabr/navbar";
import "./companies.css";

function IntrestSent() {
  const [activeAccordion, setActiveAccordion] = useState("0");

  const handleAccordionClick = (eventKey) => {
    setActiveAccordion(activeAccordion === eventKey ? null : eventKey);
  };

  return (
    <>
      <TopNavbar></TopNavbar>
      <div className="main-wrapper">
        <div>
          <Sidebar></Sidebar>
        </div>
        <div className="main_contant">
          <div className="container-fluid all_page_for_top_heading">
            <h2 className=" text-uppercase all_heading">Companies</h2>
          </div>
          <div className="container-fluid companies_section_main_div mb-3">
            <div className="d-flex align-items-center justify-content-between companies_select_all_div">
              <div className="d-flex align-items-center companies_beep_cart_img">
                <img
                  src="/charaiteImage/BeepKart.png"
                  alt="Company Logo"
                  className="rounded"
                  style={{ marginRight: "10px" }}
                />
                <div>
                  <h6 className="mb-0" style={{ fontSize: "16px" }}>
                    Portfolio Company Name...
                  </h6>
                  <small className="text-muted">Consumer Tech, B2B</small>
                </div>
              </div>
              <div className="profile_submit_btn">
                <button type="button">Interest Sent</button>
              </div>
            </div>
            <Accordion
              activeKey={activeAccordion}
              onSelect={handleAccordionClick}
              className="companies_accrodian"
            >
              <Card
                style={{ border: "1px solid #e0e0e0", borderRadius: "8px" }}
              >
                <Accordion.Item eventKey="0" className="company_accodian_item">
                  <Accordion.Header>
                    <span>About the company</span>
                  </Accordion.Header>
                  <Accordion.Body
                    style={{ padding: "1rem" }}
                    className="companies_border_top"
                  >
                    <p>
                      Company Name : <span>Portfolio Company Name...</span>
                    </p>
                    <p>
                      Founder Name : <span>View company’s founder</span> name
                    </p>
                    <p>
                      CEO Name : <span>View company’s CEO name</span>
                    </p>
                    <p>
                      Stage : <span> 4 Stage</span>
                    </p>
                    <p>
                      Sector : <span>Consumer Tech, B2B</span>
                    </p>
                    <p>
                      Description :{" "}
                      <span>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout...
                      </span>
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Card>
            </Accordion>
          </div>
          {/* ///next line */}
          <div className="companies_section_main_div mb-3">
            <div className="d-flex align-items-center justify-content-between companies_select_all_div">
              <div className="d-flex align-items-center companies_beep_cart_img">
                <img
                  src="/charaiteImage/BeepKart.png"
                  alt="Company Logo"
                  className="rounded"
                  style={{ marginRight: "10px" }}
                />
                <div>
                  <h6 className="mb-0" style={{ fontSize: "16px" }}>
                    Portfolio Company Name...
                  </h6>
                  <small className="text-muted">Consumer Tech, B2B</small>
                </div>
              </div>
              <div className="profile_submit_btn">
                <button type="button">Select</button>
              </div>
            </div>
            <Accordion
              activeKey={activeAccordion}
              onSelect={handleAccordionClick}
              className="companies_accrodian"
            >
              <Card
                style={{ border: "1px solid #e0e0e0", borderRadius: "8px" }}
              >
                <Accordion.Item eventKey="1" className="company_accodian_item">
                  <Accordion.Header>
                    <span>About the company</span>
                  </Accordion.Header>
                  <Accordion.Body
                    style={{ padding: "1rem" }}
                    className="companies_border_top"
                  >
                    <p>
                      Company Name : <span>Portfolio Company Name...</span>
                    </p>
                    <p>
                      Founder Name : <span>View company’s founder</span> name
                    </p>
                    <p>
                      CEO Name : <span>View company’s CEO name</span>
                    </p>
                    <p>
                      Stage : <span> 4 Stage</span>
                    </p>
                    <p>
                      Sector : <span>Consumer Tech, B2B</span>
                    </p>
                    <p>
                      Description :{" "}
                      <span>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout...
                      </span>
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Card>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
}

export default IntrestSent;
