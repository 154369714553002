import React, {useEffect} from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Router, Route, Routes, useNavigate, useLocation } from "react-router-dom";

/**************************** Invedtor ******************************** */

import Home from "./components/Home";

import Login from "./components/Investor/Login/investors/login";
import LoginOtpVerification from "./components/Investor/Login/investors/loginOtpVerification";
import Profile from "./components/Investor/Profle/profile";
import HomePage from "./components/Investor/Home/Homepage";
import Companies from "./components/Investor/Compinies/companies";
import InvestorFeedbacks from "./components/Investor/Feedbacks/FeedbackList";
import InterestList from "./components/Investor/Interest/List";



import ScheduleMetting from "./components/Investor/ScheduleMetting/scheduleMetting";
import IntrestSent from "./components/Investor/Compinies/intrestSent";
import MettingDetails from "./components/Investor/Metting/MettingList";

/**************************** Company ******************************** */
import CompanyLogin from './components/Company/Login/company/login'
import CompanyVerifyOTP from './components/Company/Login/company/loginOtpVerification'

import MainHomePage from "./components/Company/Home/Homepage";
import PortfolioProfile from "./components/Company/Profle/profile"
import EditPortfolioProfile from "./components/Company/Profle/editProfile";
import CompanyFeedbacks from "./components/Company/Feedbacks/FeedbackList";
import InvestorInterestList from "./components/Company/Interest/List";


import PortfolioMetting from "./components/Company/Metting/NoMetting";
import CompanyMettingList from "./components/Company/Metting/MettingList";
// import PortfolioMettingDetailsSecond from "./components/Company/Metting/mettingDetailsSecond";
import PortfolioscheduleMetting from "./components/Company/ScheduleMetting/scheduleMetting";
import Feedback from "./components/Investor/feedBack/feedback";

import CacelTextRequest from "./components/Investor/Reschedule Request/cacelTextRequest";
import CancelRequest from "./components/Investor/Reschedule Request/cancelRequest";
import ReScheduleRequest from "./components/Investor/Reschedule Request/reScheduleRequest";
import ReScheduleTextRequest from "./components/Investor/Reschedule Request/reScheduleTextRequest";
import FeedbackPopup from "./components/Investor/feedBack/feedbackPopup";
import Portfolio_CancelRequest from "./components/Company/Reschedule Request/cancelRequest";
import Portfolio_CacelTextRequest from "./components/Company/Reschedule Request/cacelTextRequest";
import Portfolio_ReSchedule from "./components/Company/Reschedule Request/reScheduleTextRequest";
import Portfolio_ReScheduleRequest from "./components/Company/Reschedule Request/reScheduleRequest";

/******************************* END ************************************/

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = JSON.parse(sessionStorage.getItem('USER-INFO'));
  const TOKEN = sessionStorage.getItem('TOKEN');
  useEffect(() => {
    if (!["/"].includes(location?.pathname)) {
      if (!TOKEN) {
        navigate("/");
      }
    }
  }, [userData]);
    // if(["/investor-login", "/verify-login-otp","/investor/profile","/investor/companies","/investor/meetings","/investor/feedbacks","/investor/interest"].includes(location?.pathname)){
    //   if (!userData || !userData.token) {
    //     // navigate('/investor-login');
    //     navigate('/');
    //   } else if(userData && userData.first_time_login === true){
    //     navigate('/investor/profile');
    //   }
    // } else{
    //   if (!userData || !userData.token) {
    //     // navigate('/company-login');
    //     navigate('/');
    //   } else if(userData && userData.first_time_login === true){
    //     navigate('/company/profile');
    //   }
    // }
    
  
  return (
    <div className="App container-fluid">
      {/* <TopNavbar /> */}
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/investor-login" element={<Login />} />
            <Route path="/investor/verify-login-otp" element={<LoginOtpVerification />} /> */}
            <Route path="/investor/dashboard" element={<HomePage />} />
            <Route path="/investor/profile" element={<Profile/>} />
            <Route path="/investor/companies" element={<Companies />}></Route>
            <Route path="/investor/interest" element={<InterestList />}></Route>
            {/* <Route path="/investor/interest/:search" element={<InterestList />}></Route> */}
            <Route path="/investor/meetings" element={<MettingDetails />}></Route>
            <Route path="/investor/feedbacks" element={<InvestorFeedbacks />}></Route>

            <Route path="/schedule-metting" element={<ScheduleMetting />} />
            <Route path="/intrest-sent" element={<IntrestSent />}></Route>
            <Route path="/feedbacks" element={<Feedback />}/>
            <Route path="/CacelTextRequest" element={<CacelTextRequest />}/>
            <Route path="/CancelRequest" element={<CancelRequest />}/>
            <Route path="/ReScheduleRequest" element={<ReScheduleRequest />}/>
            <Route path="/ReScheduleTextRequest" element={<ReScheduleTextRequest />}/>
            <Route path="/FeedbackPopup" element={<FeedbackPopup />}/>

            {/* *************************** Company Routes ********************* */}
            {/* <Route path="/company-login" element={<CompanyLogin />} />
            <Route path="/company/verify-login-otp" element={<CompanyVerifyOTP />} /> */}
            <Route path="/company/dashboard" element={<MainHomePage />} />
            <Route path="/company/profile" element={<PortfolioProfile/>} />
            {/* <Route path="/company/interest/:search" element={<InvestorInterestList />}></Route> */}
            <Route path="/company/interest" element={<InvestorInterestList />}></Route>
            <Route path="/company/meetings" element={<CompanyMettingList />}></Route>
            <Route path="/company/feedbacks" element={<CompanyFeedbacks />}></Route>

            {/* <Route path="/Homeportfolio" element={<MainHomePage />} />
            <Route path="/portfolio-profile" element={<PortfolioProfile />} /> */}
            <Route path="/editportfolio-profile" element={<EditPortfolioProfile />} />
            <Route path="/portfolio-metting" element={<PortfolioMetting />} />
            
            <Route path="/portfolioschedulemetting" element={<PortfolioscheduleMetting />} />
            {/* <Route path="/portfoliometting-detailssecond" element={<PortfolioMettingDetailsSecond />} /> */}

            <Route path="/portfolio-cancelrequest" element={<Portfolio_CancelRequest />}></Route>
            <Route path="/portfolio-caceltextrequest" element={<Portfolio_CacelTextRequest />}></Route>
            <Route path="/portfolio-reschedule" element={<Portfolio_ReSchedule />}></Route>
            <Route path="/portfolio-recchedulerequest" element={<Portfolio_ReScheduleRequest />}></Route>

          </Routes>
    </div>
  );
};

export default App;
