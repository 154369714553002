import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CompanyCard from "../Skelton/CompanyCard";
import SelectPopUp from '../Interest/Popup';
import {notification} from 'antd'

import {
  InboxOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import {changeStatus} from '../../../controllers/interest/interestController'

const HomeSlider = ({list, setHomeSliderReload}) => {
  const [isSelectShow, setIsSelectShow]= useState(false);
  const handleIsSelectShow =() => setIsSelectShow(!isSelectShow);



  const [interestId, setInterestId] = useState('');
  const handleSelect=(id)=>{ 
    setInterestId(id);
    handleIsSelectShow();
  }

  const sliderRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (sliderRef.current) {
        sliderRef.current.slickGoTo(0);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(()=>{
    setHomeSliderReload();
  },[interestId]);


  const [isOpen, setIsOpen] = useState(false);
  const [priority, setPriority] = useState('');
  const handleCloseModal = () => {
    setIsOpen(false);
    setInterestId('')
    setPriority('')
    handleIsSelectShow();
  }

  const handleSubmit = async () =>{
    try {
      if(!priority){
        notification.open({
          message: "Opps!",
          description: `Please select priority.`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      } else if(!interestId){
        notification.open({
          message: "Opps!",
          description: `Data not found.`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
        handleCloseModal();
      } else {
        const data = {
          interest_id : interestId,
          priority : priority,
          status : "A"
        }
        const res = await changeStatus(data);
        if(res.status === true){
          notification.open({
            message: "Success",
            description: `Interest accepted successfully.`,
            placement: "topRight",
            icon: <CheckCircleOutlined style={{ color: "green" }} />,
            duration: 2,
          });
          setHomeSliderReload();
        } else {
          notification.open({
            message: "Oops!!",
            description: `${res.message}`,
            placement: "topRight",
            icon: <InfoCircleOutlined style={{ color: "red" }} />,
            duration: 2,
          });
        }
        handleCloseModal();
      }
    } catch (error) {
      
    }
  }

  return (
    <React.Fragment>
      <div className="container mt-3 home_bottom_carousls">
        {/* <Slider ref={sliderRef} {...settings}>
          <div className="px-2 ">
            <div className=" h-100 home_slider_card">
              <div className="card-body d-flex flex-column home_slider_img_content">
                <img
                  src="/charaiteImage/beepkar1.png"
                  alt="BonusHub"
                  className="mb-2"
                />
                <h5 className="card-title">Portfolio Company Name...</h5>
                <p className="card-text">Consumer Tech, B2B</p>
                <p className="card-text flex-grow-1 home_slider_bonus_hub">
                  BonusHub is revolutionizing the rewards industry with
                  innovative solutions for businesses...
                </p>
                <div className="d-flex justify-content-between mt-4">
                <div className="home_select">
                    <button type="button">Accept</button>
                  </div>
                  <div className="home_select">
                    <button type="button">Decline</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-2">
            <div className="home_slider_card h-100">
              <div className="card-body d-flex flex-column home_slider_img_content">
                <img
                  src="/charaiteImage/aether.png"
                  alt="BonusHub"
                  className="mb-2"
                />
                <h5 className="card-title">Portfolio Company Name...</h5>
                <p className="card-text">Consumer Tech, B2B</p>
                <p className="card-text flex-grow-1 home_slider_bonus_hub">
                  BonusHub is revolutionizing the rewards industry with
                  innovative solutions for businesses...
                </p>
                <div className="d-flex justify-content-between mt-4">
                <div className="home_select">
                    <button type="button">Accept</button>
                  </div>
                  <div className="home_select">
                    <button type="button">Decline</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-2 ">
            <div className="home_slider_card h-100">
              <div className="card-body d-flex flex-column home_slider_img_content">
                <img
                  src="/charaiteImage/bonushub.png"
                  alt="BonusHub"
                  className="mb-2"
                />
                <h5 className="card-title">Portfolio Company Name...</h5>
                <p className="card-text">Consumer Tech, B2B</p>
                <p className="card-text flex-grow-1 home_slider_bonus_hub">
                  BonusHub is revolutionizing the rewards industry with
                  innovative solutions for businesses...
                </p>
                <div className="d-flex justify-content-between mt-4">
                <div className="home_select">
                    <button type="button">Accept</button>
                  </div>
                  <div className="home_select">
                    <button type="button">Decline</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider> */}
        <Slider ref={sliderRef} {...settings}>
        {list?.length > 0 ? (
            list.map((items, index) => (

              <div key={index}  className="px-2 ">
                <div className=" h-100 home_slider_card">
                  <div className="card-body d-flex flex-column home_slider_img_content">
                    <img
                      src="/charaiteImage/beepkar1.png"
                      alt="BonusHub"
                      className="mb-2"
                    />
                    <h5 className="card-title">{items?.investor_data?.investor_firm_name?.length > 22 ?`${items?.investor_data?.investor_firm_name.subString(0,22)}...`:`${items?.investor_data?.investor_firm_name}`}</h5>
                    <p className="card-text">Mr. {items?.investor_data?.investor_first_name} {items?.investor_data?.investor_last_name?.length > 30 ?`${items?.investor_data?.investor_last_name.subString(0,12)}...`:`${items?.investor_data?.investor_last_name}`}</p>
                    <p className="card-text flex-grow-1 home_slider_bonus_hub">
                      {items?.investor_data?.investor_designation}
                    </p>
                    <div className="d-flex justify-content-between mt-4">
                    <div className="home_select">
                        <button type="button" onClick={()=>handleSelect(items?._id)}>Accept</button>
                      </div>
                      <div className="home_select">
                        <button type="button">Decline</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))) : Array.from({ length : 5 }, (_, index) => ( <CompanyCard key={index} />)) }
        </Slider>
      </div>
      <SelectPopUp 
        show={isSelectShow} 
        handleIsSelectShow={handleIsSelectShow} 
        interestId={interestId}
        setInterestId={setInterestId} />

      <div
        className="modal fade show"
        style={{ display: `${isSelectShow?'block':'none'}`, backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: "400px" }}
        >
          <div className="modal-content">
            <div className="modal-body text-center p-4 login_popup_modal">
              <h5 className="mb-4">Select the priority <br /> for the company</h5>
              <div className="companies_popup_all_section">
                <div className="d-flex justify-content-center mb-3 ">
                  <div className="form-check me-4 companies_popup_radio_btn" onClick={()=>setPriority(1)}>
                    <input
                      className="form-check-input all_radio_btn"
                      type="radio"
                      name="priority"
                      id="priority1"
                      checked={priority===1?true:false}
                      value="1"
                    />
                    <label className="form-check-label" htmlFor="priority1">
                      Priority 1
                    </label>
                  </div>
                  <div className="form-check companies_popup_radio_btn" onClick={()=>setPriority(2)}>
                    <input
                      className="form-check-input all_radio_btn"
                      type="radio"
                      name="priority"
                      id="priority2"
                      value="2"
                      checked={priority===2?true:false}
                    />
                    <label className="form-check-label" htmlFor="priority2">
                      Priority 2
                    </label>
                  </div>
                </div>
                <button type="button" className="btn btn-outline-danger mt-2 mb-4" onClick={handleSubmit} >
                  Submit
                </button>
              </div>
            </div>
            <div className="position-absolute user_login_top_right_cross_btn">
              <button className="top-0 end-0 m-3" onClick={handleCloseModal}>
                x
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default HomeSlider;
