import  { API_BASE_URL }  from '../../config/constants';
import {getRequest, postRequest} from '../API';

/*********************************************************
 *  This function is use to login api
 *********************************************************/
export const login = async (options)=>{
    try{
        const {email}= options;
        if(!email){
            return {status : false, message:"Email Address/ Mobile Number is required"}
        } else{
            const posrData ={ 
                url : API_BASE_URL+'/company/login',
                postData : options
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                return {status : true, message:`Your One Time Password is sent to ${email}`}
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
        }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function

/*********************************************************
 *  This function is use to login api
 *********************************************************/
export const verifyOTP = async (options)=>{
    try{
        const {email}= options;
        if(!email){
            return {status : false, message:"Your one-time password has expired or is incorrect. Please try again."}
        } else{
            const posrData ={ 
                url : API_BASE_URL+'/company/verify-login-otp',
                postData : options
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                sessionStorage.setItem('TOKEN', res.data.response.result.token);
                sessionStorage.setItem('USER-INFO', JSON.stringify(res.data.response.result));
                return {status : true, message:`Welcome back! ${res.data.response.result.investor_first_name}`, result : res?.data?.response?.result };
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
        }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function

/*********************************************************
 *  This function is use to logout user and clear session and local storage
 *********************************************************/
export const logout = async() => {
    try{
        const posrData ={ 
            url : API_BASE_URL+'/company/logout',
         }
        await getRequest(posrData);
        sessionStorage.clear();
        localStorage.clear();
        return {status : true, message:"Success"}
    } catch(error){
        sessionStorage.clear();
        localStorage.clear();
        return {status : true, message:"Success"}
    }   
}

/*********************************************************
* Function Name : updateProfile
* Description   : this function is used for updateProfile
* By            : Afsar Ali
* Date          : 19-AUG-2024 
*********************************************************/
export const updateProfile = async (options) =>{
    try {
        const params = {
            url : `${API_BASE_URL}/company/update-profile`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            sessionStorage.setItem('USER-INFO', JSON.stringify(res?.data?.response?.result))
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : List
* Description   : Get list of all companies
* By            : Afsar Ali
* Date          : 20-08-2024 
*********************************************************/
export const list = async (options) =>{
    try {
        const params = {
            url : `${API_BASE_URL}/company/list`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result, count : res?.data?.response?.count};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

