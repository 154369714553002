import Portfoliosidebar from "../Sidebar/Portfoliosidebar";
import "../../Investor/Metting/metting.css";
import TopNavbar from "../../Investor/Navabr/navbar";
import mettingLottie from "./metiingLottie.json";
import Lottie from "react-lottie";

function NoMetting() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: mettingLottie, // Correct key here
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="main_contant">
      <div className="container-fluid py-5  metting_main_section">
        <h2 className=" text-uppercase all_heading">Scheduled Meetings</h2>
        <div className="no_metting_to_show">
          <div className="d-flex justify-content-center metting_animation_sec">
            <Lottie options={defaultOptions} />
            <button className="mt-3">No meetings to show</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoMetting;
