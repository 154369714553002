import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CompanyCard from "../Skelton/CompanyCard";
import SelectPopUp from '../Compinies/Popup';
import { useNavigate } from "react-router-dom";
import { NOIMAGE } from "../../../config/constants";
import { Skeleton } from "antd";

const  HomeSlider = ({list}) => {
  const interestedCompanyList = JSON.parse(sessionStorage.getItem('INTEREST-DATA'));
  const navigate = useNavigate();
  const [isSelectShow, setIsSelectShow]= useState(false);
  const handleIsSelectShow =() => setIsSelectShow(!isSelectShow);
  const [companyId, setCompanyId] = useState('');
  const handleSelect=(id)=>{ 
    setCompanyId(id);
    handleIsSelectShow();
  }
  const sliderRef = useRef(null);
  useEffect(() => {
    const handleResize = () => {
      if (sliderRef.current) {
        sliderRef.current.slickGoTo(0);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  
  return (
    <div>
      <div className="container mt-3 home_bottom_carousls">
        <Slider ref={sliderRef} {...settings}>
        {list?.length > 0 ? (
            list.map((items, index) => (
              <div key={index} className="px-2 ">
                <div className=" h-100 home_slider_card">
                  <div className="card-body d-flex flex-column home_slider_img_content">
                    {items?.company_image ? (
                      <img
                        src={ items?.company_image}
                        alt="BonusHub"
                        className="mb-2 investor_slidesimg"
                      />
                    ):( <Skeleton avatar paragraph={false} title={false} /> )}
                    <h5 className="card-title">{items?.company_name?.length > 22 ?`${items.company_name.substring(0,22)}...`:items?.company_name}</h5>
                    <p className="card-text">{items?.company_sector?.length > 22 ?`${items.company_sector.substring(0,30)}...`:items?.company_sector}</p>
                    <p className="card-text flex-grow-1 home_slider_bonus_hub">
                    {items?.company_description?.length > 30 ?`${items.company_description.substring(0,40)}...`:items?.company_description}
                    </p>
                      {interestedCompanyList?.includes(items._id)?(
                        <div className="d-flex justify-content-between mt-4">
                          <div className="home_select_know">
                            <button type="button" onClick={()=>navigate('/investor/companies')}>Know more</button>
                          </div>
                          <div className="started_in_mint_ago">
                              <button type="button">Interest Sent</button>
                          </div>
                        </div>
                      ):(
                        <div className="d-flex justify-content-between mt-4">
                          <div className="home_select_know">
                            <button type="button" onClick={()=>navigate('/investor/companies')}>Know more</button>
                          </div>
                          <div className="home_select">
                              <button type="button" onClick={()=>handleSelect(items._id)}>Select</button>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            ))) : Array.from({ length : 5 }, (_, index) => ( <CompanyCard key={index} />)) }
        </Slider>
      </div>
      <SelectPopUp 
        show={isSelectShow} 
        handleIsSelectShow={handleIsSelectShow} 
        companyId={companyId}
        setCompanyId={setCompanyId} />
    </div>
  );
}

export default HomeSlider;
