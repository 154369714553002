import  { API_BASE_URL }  from '../../config/constants';
import {getRequest, postRequest} from '../API';

/*********************************************************
* Function Name : createInterest
* Description   : This function is used for create Interest
* By            : Afsar Ali
* Date          : 20-08-2024 
*********************************************************/
export const createInterest = async (options) =>{
    try {
        const params = {
            url : `${API_BASE_URL}/interest/create`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : list
* Description   : This function is used for get Interest list
* By            : Afsar Ali
* Date          : 21-08-2024 
*********************************************************/
export const interestList = async (options) =>{
    try {
        const params = {
            url : `${API_BASE_URL}/interest/list`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : changeStatus
* Description   : This function is used for chnage status of Interest
* By            : Afsar Ali
* Date          : 21-08-2024 
*********************************************************/
export const changeStatus = async (options) =>{
    try {
        const params = {
            url : `${API_BASE_URL}/interest/chnage-status`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function
