import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { RxCrossCircled } from "react-icons/rx";
// import "../../../Investor/Login/login.css";

import {
  InboxOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { login } from '../../../../controllers/company/accounts';
import{ notification, Flex, Spin } from 'antd';
import { useNavigate } from "react-router-dom";
import LoginOtpVerification from './loginOtpVerification';

const  Login = ({companyPopup, handleCompanyPopup}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isVerifyOTP, setIsVerifyOTP] = useState(false);
  const handleVerityOTPPopup = () =>{
    setIsVerifyOTP(!isVerifyOTP);
  }
  const handleOpenModal = () => setIsOpen(true);
  const handleCloseModal = () => {
    setEmail('');
    handleCompanyPopup();
  }

  const [email, setEmail] = useState('');
  const handleChange = (e)=>{
    const {value }= e.target;
    setEmail(value);
  }
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      var isNumber = /^\d+$/;
      var numberReg = /^[2-9][0-9]{9}$/;
      var emailRegx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if(isNumber.test(email) && !numberReg.test(email)){
          notification.open({
            message: "Opps!",
            description: `Please enter a valid mobile number.`,
            placement: "topRight",
            icon: <InfoCircleOutlined style={{ color: "red" }} />,
            duration: 2,
          });
      } else if(!numberReg.test(email) && !emailRegx.test(email)){
        notification.open({
          message: "Opps!",
          description: `Please enter a valid email address.`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }else{
        const options = { email : email };
        const res = await login(options);
        if(res.status === true){
          notification.open({
            message: "Success",
            description: `${res?.message}`,
            placement: "topRight",
            icon: <CheckCircleOutlined style={{ color: "green" }} />,
            duration: 2,
          });
          // navigate('/company/verify-login-otp', {state : email} )
          handleCompanyPopup();
          handleVerityOTPPopup()
        } else{
          notification.open({
            message: "Opps!",
            description: `${res?.message}`,
            placement: "topRight",
            icon: <InfoCircleOutlined style={{ color: "red" }} />,
            duration: 2,
          });
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // console.log('error',error)
    }
  }
  const handleEmailKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };
  return (
    <>
      <div
        className="modal fade show"
        style={{ display: `${companyPopup===true?"block":"none"}`, backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: "400px" }}
        >
          <div className="modal-content">
            <div className="modal-body text-center p-4 login_popup_modal">
              <img
                src="./charaiteImage/chirate_logo.png"
                alt="Logo"
             
                className="mb-4 popup_logo"
              />
              <h5 className="mb-4  login_your_account">
                Login as company
              </h5>
              <div className="mb-3 login_email_mobile_no">
                {/* <label htmlFor="loginInput" className="form-label mb-4">
                  Email Address/ Mobile Number
                </label> */}
                <input
                  type="text"
                  name='email'
                  value={email?email:''}
                  id="loginInput"
                  className="form-control store_input_field_field mb-4"
                  placeholder="Enter mobile no./email address"
                  onChange={handleChange}
                  onKeyDown={handleEmailKeyPress}
                  style={{ backgroundColor: "#f0f0f0", borderRadius: "20px" }}
                />
              </div>
              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-outline-danger mt-2 mb-4"
                style={{
                  borderRadius: "30px",
                  width: "57%",
                  padding: "5px 0px 9px",
                }}
              >
                Proceed to Next
              </button>
            </div>
            <div className="position-absolute user_login_top_right_cross_btn">
              <button className="top-0 end-0 m-3" onClick={handleCloseModal}>
              <RxCrossCircled />
              </button>
            </div>
          </div>
        </div>
        <Flex align="center" gap="middle">
          <Spin 
              size="large" 
              spinning={isLoading}
              fullscreen={true}
          />
        </Flex>
      </div>
      <LoginOtpVerification isVerifyOTP={isVerifyOTP} handleVerityOTPPopup={handleVerityOTPPopup} loginEmail={email} />
    </>
  );
}
export default Login;
