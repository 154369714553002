import Sidebar from "../Sidebar/sidebar";
import TopNavbar from "../Navabr/navbar";
import HomeSlider from "../Home/homeSlider";
import ScheduleMettingSlider from "./scheduleMettingSlider";
import "../Home/homePage.css";
import { FaUserCircle, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
const ScheduleMetting = () => {
  return (
    <>
      <TopNavbar></TopNavbar>
      <div className="main-wrapper">
        <div>
          <Sidebar></Sidebar>
        </div>
        <div className="main_contant">
          <>
            <div className="container-fluid  all_page_for_top_heading">
              <h2 className=" text-uppercase all_heading">Dashboard</h2>
              <div className="">
                <h4 className="profile_info_heading">Profile Info</h4>
              </div>
              <div className="row mb-2">
                <div className="col">
                  <div className="card  mt-2 home_investor">
                    <div className="row">
                      <div className="col-md-4 d-flex align-items-center profile_investor_company_name">
                        <FaUserCircle className="me-2 " />
                        <span>Investors Company Name</span>
                      </div>
                      <div className="col-md-4 d-flex align-items-center profile_investor_company_name">
                        <FaPhoneAlt className="me-2 " />
                        <span>+971-XX-1234567</span>
                      </div>
                      <div className="col-md-4 d-flex align-items-center profile_investor_company_name">
                        <FaEnvelope className="me-2 " />
                        <span>dineatadm@address.com</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col home_new_company">
                  <div className="mb-2 mt-2">
                    <h4 className="profile_info_heading">Scheduled Meetings</h4>
                  </div>
                  <div className="profile_submit_btn mt-2">
                    <button type="button">View All</button>
                  </div>
                </div>
                <ScheduleMettingSlider></ScheduleMettingSlider>
              </div>

              <div className="row">
                <div className="col home_new_company">
                  <div>
                    <h4 className="home_new_company_heading">New Companies</h4>
                  </div>
                  <div className="profile_submit_btn">
                    <button type="button">View All</button>
                  </div>
                </div>
              </div>
              <HomeSlider></HomeSlider>
            </div>
          </>
        </div>
      </div>
    </>
  );
};
export default ScheduleMetting;
