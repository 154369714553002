import { useState, useEffect, useRef } from "react";
import { Accordion, Card } from "react-bootstrap";
import TopNavbar from "../../Investor/Navabr/navbar";
import Sidebar from "../Sidebar/sidebar";
import { BsThreeDotsVertical } from "react-icons/bs";
// import "../../Investor/Compinies/companies.css";
import "../Compinies/companies.css";
import moment from "moment";

import NoMetting from "./NoMetting";
import {Skeleton} from 'antd';

import { meetingList } from "../../../controllers/meetings/meetingController";
import { Link } from "react-router-dom";
import CancelRequest from "./CancelRequest";
import RescheduleRequest from "./RescheduleRequest";
import { createImageFromInitials } from "../../../controllers/common";
import { FaTh, FaServer } from "react-icons/fa";

const MettingList = () => {
  const targetRef = useRef();
  const [activeAccordion, setActiveAccordion] = useState(0);
  const [dataView, setDataView]= useState('grid');
  const handleAccordionClick = (eventKey) => {
    if(eventKey !== null){
      setActiveAccordion(activeAccordion === eventKey ? null : eventKey);
    }
  };

  const [dropdown, setDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const [reschedulePopupShow, setReschedulePopupShow] = useState(false);
  const handleReschedulePopup = () =>{
    setReschedulePopupShow(!reschedulePopupShow);
  }

  const [cancelPopupShow, setCancelPopupShow] = useState(false);
  const handleCancelPopup = () =>{
    setCancelPopupShow(!cancelPopupShow);
  }

  
  const [page, setPage] =useState('List');
  const [isLoading, setIsLoading] =useState(true);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [TOTALCOUNT, setTotalCount] = useState(0);
  const [LIMIT, setLimit] = useState(5);
  const [SKIP, setSkip] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [accordionBody, setAccordionBody] = useState({})

  const handleAccordionBody = (index)=>{
    setAccordionBody((pre)=>({
      ...pre,
      [index] : !accordionBody[index]
    }))
  }

  const [meetingId, setMeetingId] = useState('');


  const dropdown_handle = (index) => {
    setDropdown((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdown(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [page]);

  const getMeeting = async () => {
    try {
      setIsLoading(true);
      setListData([]);
      const options = {
        type: "",
        ...(searchText?{searchText : searchText}:null),
        condition: {  },
        select: {},
        sort: { _id: -1 },
        // skip: SKIP ? SKIP : 0,
        // limit: LIMIT ? LIMIT : 5,
      }
      const res = await meetingList(options);
      if(res.status === true){
        setListData(res.result);
        setTotalCount(res.count);
        if(res?.result?.length === 0){
          setPage('Empty');
        }
      }
      setIsLoading(false)
    } catch (error) {
      setListData('');
      setListData(0)
      setIsLoading(false);
    }
  }

  const [expanded, setExpanded] = useState({});

  const toggleExpand = (index) => {
    setExpanded((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleSearchChnage = (e) =>{
    const {value} = e.target;
    if(e.key === 'Enter'){
      handleSearch(e);
    }else{
      setSearchText(value);
    }
  }
  const handleSearch = (e) => {
    e.preventDefault();
    // if(searchText.length > 0){
      setIsSearch(!isSearch);
    // }
  }

  useEffect(()=>{
    getMeeting();
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
    document.title = "Chiratae Ventures | Spotlight | Meeting list";
  },[page,isSearch]);


  const loadMoreData = async () => {
    try {
      const skip = ALLLISTDATA.length;
      const options = {
        type: "",
        condition: { },
        select: {},
        sort: { _id: -1 },
        skip: skip ? skip : 0,
        limit: 5,
      }
      const res = await meetingList(options);
      if(res?.status === true || res?.result?.length > 0){
        setListData((prev) => [...prev, ...res.result]);
      }
    } catch (error) {
      
    }
  }
  const lastItemRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if(TOTALCOUNT !== ALLLISTDATA.length){
            // loadMoreData();
          }
          // Optionally disconnect the observer if you only need the alert once
          observer.disconnect();
        }
      },
      { threshold: 1.0 } // Adjust threshold as needed
    );

    if (lastItemRef.current) {
      observer.observe(lastItemRef.current);
    }

    return () => {
      if (lastItemRef.current) {
        observer.unobserve(lastItemRef.current);
      }
    };
  }, [ALLLISTDATA]);


  const dataTimeFormat = (date) => {
    if (date && typeof date === 'string') {
      const dateParts = date.split('-');
      if (dateParts.length === 3) {
          const formattedDate = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
          const options = { day: '2-digit', month: 'long', year: 'numeric' };
          const convertedDate = formattedDate.toLocaleDateString('en-US', options);
          return convertedDate
      } else {
          console.error('Invalid date format');
      }
    } else {
        console.error('Date string is undefined or not a string');
    }
  }

  const timeDifferance = (time_from , time_to ) => {
    const [fromHours, fromMinutes] = time_from.split(':').map(Number);
    const [toHours, toMinutes] = time_to.split(':').map(Number);

    const fromDate = new Date(2024, 7, 24, fromHours, fromMinutes); 
    const toDate = new Date(2024, 7, 24, toHours, toMinutes);

    const diffInMilliseconds = toDate - fromDate;

    const diffInMinutes = diffInMilliseconds / (1000 * 60);

    const diffHours = Math.floor(diffInMinutes / 60);
    const diffMinutes = diffInMinutes % 60;

    return `${diffHours} hours and ${diffMinutes} minutes`
  }

  const toggleCancel = (index) => {
    // setPage('Cancel');
    setMeetingId(ALLLISTDATA[index]?._id);
    dropdown_handle(index);
    handleCancelPopup();
  }

  const toggleReschedule = (index) => {
    setPage('Reschedule');
    setMeetingId(ALLLISTDATA[index]?._id);
    dropdown_handle(index);
  }


  return (
    <>
      <TopNavbar />
      <div className="main-wrapper" ref={targetRef}>
        <div>
          <Sidebar />
        </div>
        
        {page === 'List' && (
          <div className="main_contant">
            <div className="container-fluid all_page_for_top_heading">
              <h2 className=" text-uppercase all_heading">Scheduled Meetings</h2>
            </div>
            <div className="container-fluid all_page_for_top_heading">
              <form onSubmit={handleSearch}>
                <div className="row">
                  <div className="col-sm-9 col-md-9 col-lg-9">
                    <input className="form-control all_input" type="text" name="search" id="search" onChange={handleSearchChnage} value={searchText} placeholder="Search interest..."/>
                  </div>
                  <div className="col-sm-2 col-md-2 col-lg-2">
                    <div className="profile_submit_btn">
                      <button type="submit" id="selected">Search</button>
                    </div>
                  </div>
                  <div className="col-sm-1 col-md-1 col-lg-1">
                  {dataView === 'table' ? (
                    <div title="Grid view"  className="profile_submit_btn" onClick={()=>setDataView('grid')}>
                      <button type="button" id="selected"><FaTh/></button>
                    </div>
                  ) : (
                    <div title="Table view" className="profile_submit_btn" onClick={()=>setDataView('table')}>
                      <button type="button" id="selected"><FaServer/></button>
                    </div>
                  )}
                </div>
                </div>
              </form>
            </div>
            {ALLLISTDATA?.length > 0 ? 
              dataView === 'grid' ? (
              ALLLISTDATA.map((items, index) => (
                <div key={index} className="container-fluid companies_section_main_div mb-3" ref={index === ALLLISTDATA.length - 1 ? lastItemRef : null}>
                  <div className="d-flex align-items-center justify-content-between companies_select_all_div">
                    <div className="d-flex align-items-center companies_beep_cart_img">
                      {/* <img
                        src="/charaiteImage/BeepKart.png"
                        alt="Company Logo"
                        className="rounded"
                        style={{ marginRight: "10px" }}
                      /> */}
                      {items?.company_image?(
                        <img
                          src={items?.company_image}
                          alt="Company Logo"
                          className="rounded"
                          style={{ marginRight: "10px" }}
                        />
                      ):(
                        <img
                          src={createImageFromInitials(100, `${items?.company_name.length > 8?items.company_name.substring(0,8)+'..':items?.company_name}`, '#ededed')}
                          alt="Company Logo"
                          className="rounded"
                          style={{ marginRight: "10px"}}
                        />
                      )}
                      <div>
                        <h6 className="mb-0" style={{ fontSize: "16px" }}>
                        {items?.company_id?.company_name}
                        </h6>
                        <small className="text-muted">{`${items?.company_id?.company_sector}`}</small>
                      </div>
                    </div>
                    <div className="d-flex align-items-center companies_beep_cart_img companies_svg_btn">
                      {items.isCancelled === 'Y' ? (
                        <div className="started_in_mint_ago">
                          <button type="button">Meeting cancelled</button>
                        </div>
                      ):(
                        <div className="started_in_mint_ago">
                          <button type="button">{dataTimeFormat(items.date)} {moment(`2024-01-01 ${items.time_from}`).format('hh:mm:A')}</button>
                        </div>
                      )}
                      
                      <div className="metting_details_svg">
                        <BsThreeDotsVertical  onClick={()=>dropdown_handle(index)}/>
                      </div>
                      {dropdown[index] && (
                        <ul ref={dropdownRef} className="reschdule_dropdown">
                          {(items.status === 'CANCELLED' || items.isCancelled === 'Y')?(
                            <li><button className="btn-cancel">Meeting Cancelled</button></li>
                          ): 
                          (items?.isReschedule === 'Y')? (<>
                            <li><button className="btn-cancel">Reschedule request sent</button></li>
                          </>) :
                          (<>
                            <li><button className="btn-cancel" onClick={()=>toggleReschedule(index)}>Reschedule</button></li>
                            <li><button className="btn-cancel" onClick={()=>toggleCancel(index)}>Cancel</button></li>
                          </>)}
                        </ul>
                      )}
                    </div>
                  </div>
                  <Accordion
                    activeKey={activeAccordion}
                    onSelect={handleAccordionClick}
                    className="companies_accrodian"
                  >
                    <Card
                      style={{ border: "1px solid #e0e0e0", borderRadius: "8px" }}
                    >
                      <Accordion.Item eventKey={index} className="company_accodian_item">
                        <Accordion.Header className="metting_deatils_accordian">
                          <span className={ !accordionBody[index]?"active-text" : "" } onClick={()=>handleAccordionBody(index)} >
                            About the company
                          </span>
                          <span className={ accordionBody[index]?"active-text" : "" } onClick={()=>handleAccordionBody(index)}>Meeting details</span>
                        </Accordion.Header>
                        {accordionBody[index] ? (
                          <Accordion.Body style={{ padding: "1rem" }} className="companies_border_top" >
                            <p>
                              Date & Time : <span>{dataTimeFormat(items.date)} {moment(`2024-01-01 ${items.time_from}`).format('hh:mm:A')}</span>
                            </p>
                            <p>
                              Duration : <span>{timeDifferance(items.time_from, items.time_to)}</span>
                            </p>
                            {/* <p>
                              Priority : <span>{(items?.priority === 1 || items?.priority === '1')?'First' : 'Second'}</span>
                            </p>
                            <p>
                              Table No. : {items?.table}
                            </p> */}
                          </Accordion.Body>
                        ):(
                          <Accordion.Body style={{ padding: "1rem" }} className="companies_border_top" >
                            <p>
                              Company Name : <span>{items?.company_id?.company_name}</span>
                            </p>
                            {(items?.company_id?.company_founder_name !== 'undefined' && items?.company_id?.company_founder_name !== 'NA') && (
                              <p>Founder Name : <span>{`${items?.company_id?.company_founder_name}`}</span></p>
                            )}
                            {(items?.company_id?.company_ceo_name !== 'undefined' && items?.company_id?.company_ceo_name !== 'NA') && (
                              <p>CEO Name : <span>{`${items?.company_id?.company_ceo_name}`}</span></p>
                            )}
                            <p>
                              Stage : <span>{`${items?.company_id?.company_stage}`}</span>
                            </p>
                            <p>
                              Sector : <span>{`${items?.company_id?.company_sector}`}</span>
                            </p>
                            <p>
                              Description :{" "}
                              {expanded[index]?
                              <span>
                                {`${items?.company_id?.company_description} `}
                              </span> : <span>
                              {`${items?.company_id?.company_description.slice(0, 175)} `}
                              </span>}
                              {items?.company_id?.company_description?.length > 175 && (
                                <Link type="button" onClick={() => toggleExpand(index)}>
                                  {expanded[index] ? 'Read less' : 'Read more'}
                                </Link>
                              )}
                            </p>
                          </Accordion.Body>
                        )}
                        
                      </Accordion.Item>
                    </Card>
                  </Accordion>
                </div>
              ))
            ) : (
              <div id="content-wrapper" className="d-flex flex-column">
                <div className=" mb-4">
                  <div className="card-body pt-0">
                    <div className="table-responsive">
                    <table
                        className="table table-bordered"
                        id="dataTable"
                        width="100%"
                        cellspacing="0"
                        >
                        <thead>
                          <tr>
                            <th>Sr. no.</th>
                            <th>Company</th>
                            <th>Founder/CEO</th>
                            <th>Stage</th>
                            <th>Sector</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {ALLLISTDATA.map((items, index) => (
                            <tr key={index} ref={index === ALLLISTDATA.length - 1 ? lastItemRef : null}>
                              <td>{index +1}</td>
                              <td className="admin_user_list_date"> {items?.company_id?.company_name} </td>
                              <td className="admin_user_list_date"> {items?.company_id?.company_founder_name?`${items.company_id.company_founder_name} (Founder)`:`${items?.company_id?.company_ceo_name}(CEO)`} </td>
                              <td className="admin_user_list_date"> {items?.company_id?.company_stage} </td>
                              <td className="admin_user_list_date"> {items?.company_id?.company_sector} </td>
                              <td>
                                <div className="metting_details_svg">
                                  <BsThreeDotsVertical  onClick={()=>dropdown_handle(index)}/>
                                </div>
                                {dropdown[index] && (
                                  <ul ref={dropdownRef} className="reschdule_dropdown">
                                    {(items.status === 'CANCELLED' || items.isCancelled === 'Y')?(
                                      <li><button className="btn-cancel">Meeting Cancelled</button></li>
                                    ): 
                                    (items?.isReschedule === 'Y')? (<>
                                      <li><button className="btn-cancel">Reschedule request sent</button></li>
                                    </>) :
                                    (<>
                                      <li><button className="btn-cancel" onClick={()=>toggleReschedule(index)}>Reschedule</button></li>
                                      <li><button className="btn-cancel" onClick={()=>toggleCancel(index)}>Cancel</button></li>
                                    </>)}
                                  </ul>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )
            :(<>
              <div className="container-fluid companies_section_main_div mb-3">
                <div className="d-flex align-items-center justify-content-between companies_select_all_div">
                  <Skeleton avatar/>
                </div>
              </div>
              <div className="container-fluid companies_section_main_div mb-3">
                <div className="d-flex align-items-center justify-content-between companies_select_all_div">
                  <Skeleton avatar/>
                </div>
              </div>
              <div className="container-fluid companies_section_main_div mb-3">
                <div className="d-flex align-items-center justify-content-between companies_select_all_div">
                  <Skeleton avatar/>
                </div>
              </div>
              <div className="container-fluid companies_section_main_div mb-3">
                <div className="d-flex align-items-center justify-content-between companies_select_all_div">
                  <Skeleton avatar/>
                </div>
              </div>
            </>)}
          </div>
        )}

        {/* {page === 'Cancel' && ( */}
          <CancelRequest 
            show={cancelPopupShow}
            handleIsSelectShow={handleCancelPopup}
            meetingId={meetingId}
            setMeetingId = {setMeetingId}
           />
        {/* )} */}

        {/* {page === 'Reschedule' && ( */}
          <RescheduleRequest 
            show={reschedulePopupShow}
            handleIsSelectShow={setReschedulePopupShow}
            meetingId={meetingId}
            setMeetingId = {setMeetingId}
          />
        {/* )} */}

        {page === 'Empty' && (
          <NoMetting />
        )}

      </div>
    </>
  );
}

export default MettingList;
