import React, { useEffect, useState } from "react";
import { FaPen } from "react-icons/fa6";
import "../../Investor/Reschedule Request/reScheduleRequest.css";
import { PiArrowArcLeftDuotone } from "react-icons/pi";
import { Skeleton, notification } from "antd";
import {
  InboxOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { reasonList, rescheduleMeeting } from '../../../controllers/meetings/meetingController';

const RescheduleRequest = ({show, handleIsSelectShow, meetingId, setMeetingId}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () =>{
    setMeetingId('');
    handleIsSelectShow()
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if(!meetingId){
        notification.open({
          message: "Opps!",
          description: `Meeting not found.`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
        handleClose();
      } else {
        const option ={
          meet_id : meetingId,
        }
        const res = await rescheduleMeeting(option);
        if(res.status === true){
          notification.open({
            message: "Success",
            description: `Reschedule request sent successfully.`,
            placement: "topRight",
            icon: <CheckCircleOutlined style={{ color: "green" }} />,
            duration: 2,
          });
          handleClose();
        }
      }
    } catch (error) {
      notification.open({
        message: "Success",
        description: `Please try again.`,
        placement: "topRight",
        icon: <CheckCircleOutlined style={{ color: "green" }} />,
        duration: 2,
      });
      handleClose();
    }
  }
  
  return (
    <div
      className="modal fade show"
      style={{ display: `${show?'block':'none'}`, backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: "400px" }}
      >
        <div className="modal-content">
          <div className="modal-body text-center p-4 login_popup_modal">
            <h5 className="mb-4">Are you sure! <br /></h5>
            <div className="companies_popup_all_section">
              <div className="d-flex justify-content-center mb-3 ">
              </div>
              {isLoading === true?(
                <Skeleton className="mt-2 mb-4" active={true} loading={true} paragraph={false}>Loading...</Skeleton>
              ):(<>
                <button type="button" className="btn btn-outline-danger mt-2 mb-4" onClick={handleSubmit} > Yes </button>
                {"  "}
                <button type="button" className="btn btn-outline-danger mt-2 mb-4" onClick={handleClose} > No </button>
              </>
              )}
            </div>
          </div>
          <div className="position-absolute user_login_top_right_cross_btn">
            <button className="top-0 end-0 m-3" onClick={handleClose}>
              x
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RescheduleRequest;
