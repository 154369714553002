import React, {useEffect, useRef, useState} from "react";
import { FaPen } from "react-icons/fa6";
import Portfoliosidebar from "../Sidebar/Portfoliosidebar";
import TopNavbar from "../../Investor/Navabr/navbar";
import { RxCross2 } from "react-icons/rx";
import { Upload, notification, InputNumber, Select, Flex, Spin } from "antd";
import {
  InboxOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import {fetchIpAddress} from '../../../controllers/API';
import { updateProfile } from '../../../controllers/company/accounts';
import { NOIMAGE } from "../../../config/constants";
const  PortfolioProfile = () => {
  const USERDATA = JSON.parse(sessionStorage.getItem('USER-INFO'));
  const targetRef =  useRef();
  const [formData, setFormData] = useState({
    company_name : USERDATA?.company_name,
    company_founder_name : USERDATA?.company_founder_name,
    company_ceo_name : USERDATA?.company_ceo_name === 'undefined'?'': USERDATA?.company_ceo_name,
    company_stage : USERDATA?.company_stage,
    company_sector : USERDATA?.company_sector,
    company_description : USERDATA?.company_description,
    company_image : USERDATA?.company_image,
    company_phone : USERDATA?.company_phone
  });

  const [companyImage, setCompanyImage]=useState(USERDATA?.investor_image);
  const [tempCompanyImage, setTempCompanyImage]=useState('');
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormData((pre)=>({
      ...pre,
      [name] : value
    }))
  }

  const sectorOption = USERDATA?.company_sector.split(',');

  const [editMode, setEditMode] = useState(false);
  const handleEditMode = () =>  setEditMode(!editMode);

  const handleSubmit = async () =>{
    try {
      setIsLoading(true);
      const ipAddress = await fetchIpAddress();
      let form = new FormData();

      form.append('company_name',formData?.company_name);
      form.append('company_founder_name',formData?.company_founder_name);
      form.append('company_ceo_name',formData?.company_ceo_name);
      form.append('company_stage',formData?.company_stage);
      form.append('company_sector',formData?.company_sector);
      form.append('company_description',formData?.company_description);
      form.append('company_phone',formData?.company_phone);
      form.append('ipAddress',ipAddress);
      if(companyImage){
        form.append('file',companyImage)
      }
      const res = await updateProfile(form);
      if(res.status === true){
        setEditMode(false);
        notification.open({
          message: "Success",
          description: `Profile updated successfully.`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        });
      } else{
        notification.open({
          message: "Oops!",
          description: `${res?.message}`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setTempCompanyImage('');
    }
  }

  useEffect(()=>{
    if(USERDATA?.first_time_login === true){
      setEditMode(true);
    }
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
    document.title = "Chiratae Ventures | Spotlight | Profile";
  },[]);

  const handleImageChnage = (e) => {
    const len = e.fileList?.length;
    const index = len - 1;
    setCompanyImage(e.fileList[index]?.originFileObj);
    const tempImg = URL.createObjectURL(e.fileList[index]?.originFileObj);
    setTempCompanyImage(tempImg);
  }

  return (
    <>
      <TopNavbar />
      <div className="main-wrapper" ref={targetRef}>
        <div>
        <Portfoliosidebar />
        </div>
        <div className="main_contant">
          <div className="container-fluid py-5 all_page_for_top_heading">
            <h2 className=" text-uppercase all_heading">Profile</h2>
            <div className="">
              <h4 className="profile_info_heading">Profile info</h4>
              <div className="d-flex align-items-center home_beepkart_fa_pen">
              {(tempCompanyImage && editMode) ? (
                  <img
                    src={tempCompanyImage}
                    alt="Logo"
                    className="profile-logo"
                    width='100px'
                  />
                ):(
                  <img
                    src={ USERDATA?.company_image || NOIMAGE }
                    alt="Logo"
                    className="profile-logo"
                    width='100px'
                  />
                )}
                {editMode && (
                  <Upload
                  showUploadList={false}
                  progress={false}
                  onChange={handleImageChnage}
                  multiple={false}
                  >
                    <div className="position-relative">
                      <button className="btn btn-sm rounded-circle edit-btn home_fa_pen_button">
                        <FaPen />
                      </button>
                    </div>
                  </Upload>
                )}
              </div>
              <form>
                <div className="row">
                  <div className="col-md-12 mb-12 invest_controls">
                    <input
                      type="text"
                      name="company_name"
                      id="company_name"
                      placeholder="Investor Company’s Name "
                      className="form-control all_input"
                      value={formData?.company_name}
                      disabled={editMode?false:true}
                      onChange={handleChange}
                    />
                  </div>
                  
                  <div className="col-md-4 mb-2">
                    <input
                      type="text"
                      name="company_founder_name"
                      id="company_founder_name"
                      placeholder="Founder Name "
                      className="form-control all_input"
                      value={formData?.company_founder_name}
                      disabled={editMode?false:true}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4 mb-2">
                    <input
                      type="text"
                      name="company_ceo_name"
                      id="company_ceo_name"
                      placeholder="CEO name"
                      className="form-control all_input"
                      value={formData?.company_ceo_name || ""}
                      disabled={editMode?false:true}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <select 
                      name="company_stage" 
                      id="company_stage" 
                      className="form-control all_input select_option"
                      disabled={editMode?false:true}
                      onChange={handleChange}
                      >
                      <option>Stage</option>
                      <option selected={(formData?.company_stage === '1' || formData?.company_stage === 1)?true:false} >Stage-first</option>
                      <option selected={(formData?.company_stage === '2' || formData?.company_stage === 2)?true:false}>Stage-second</option>
                    </select>
                  </div>
                </div>

                <div className="row expert_consumer" >
                  <div className="col-md-12">
                    {editMode?(
                      <input
                        type="text"
                        name="company_sector"
                        id="company_sector"
                        placeholder="Sector"
                        className="form-control all_input select_option"
                        value={formData?.company_sector}
                        disabled={editMode?false:true}
                        onChange={handleChange}
                      />
                    ):(
                      <Select
                        mode="multiple"
                        placeholder="Select company sectors"
                        variant="product_category"
                        name="product_category"
                        className="form-control all_input select_option"
                        style={{ flex: 1 }}
                        value={sectorOption}
                        disabled={true}
                        showSearch={false}
                      />
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-8 mb-3">
                    <input
                      type="email"
                      placeholder="Email Address"
                      className="form-control all_input"
                      value={USERDATA?.company_email}
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <input
                      type="tel"
                      name="company_phone"
                      id="company_phone"
                      placeholder="Mobile Number"
                      className="form-control all_input"
                      value={USERDATA?.company_phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <textarea
                      name="company_description"
                      id="company_description"
                      className="form-control all_input_text_area"
                      placeholder="Description"
                      rows="4"
                      value={formData?.company_description}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
                <div className="profile_submit_btn">
                  {editMode ? (
                    <button type="button" onClick={handleSubmit}>Save & Continue</button>
                  ) : (
                    <button type="button" onClick={handleEditMode}>Edit profile</button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Flex align="center" gap="middle">
        <Spin 
            size="large" 
            spinning={isLoading}
            fullscreen={true}
        />
      </Flex>
    </>
  );
}

export default PortfolioProfile;
